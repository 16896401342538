import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import NoticeBox from '../../components/shared/NoticeBox';
import HelpText from '../../components/help/HelpText';
import PopupBox from '../../components/shared/PopupBox';
import CreateRelationship from '../../components/relationships/CreateRelationship';
import UpdateRelationship from '../../components/relationships/UpdateRelationship';
import ManualCheckOutButton from '../../components/shared/ManualCheckOutButton';
import AssignVisitedPeopleModal from '../../components/shared/AssignVisitedPeopleModal';
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'
import Audits from '../../components/shared/Audits';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ViewEvent extends Component {
  constructor(props) {
    super(props);

    this.showPhoto = this.showPhoto.bind(this);
    this.showCarenoteData = this.showCarenoteData.bind(this);
    this.hideCarenoteData = this.hideCarenoteData.bind(this);
    this.handleManualCheckout = this.handleManualCheckout.bind(this);
    this.handleAssignVisitor = this.handleAssignVisitor.bind(this);
    this.handleNewRelationshipPanel = this.handleNewRelationshipPanel.bind(this);
    this.handleEditRelationshipPanel = this.handleEditRelationshipPanel.bind(this);
    this.closeNewRelationshipPanel = this.closeNewRelationshipPanel.bind(this);
    this.closeEditRelationshipPanel = this.closeEditRelationshipPanel.bind(this);
  }

  state = {
    person_id: "",
    person_name: "",
    type_of_person: "",
    check_in: "",
    check_out: "",
    reported_check_out: "",
    location: "",
    arriving_carenotes: null,
    leaving_carenotes: null,
    arriving_photo_taken: null,
    leaving_photo_taken: null,
    arriving_photo_url: null,
    leaving_photo_url: null,

    displayCarenoteData: null,

    showArrivingPhoto: false,
    showLeavingPhoto: false,

    cool_event: false,

    newPanelToggle: false,
    newPersonID: null,
    newPersonName: null,
    editPanelToggle: false,
    editID: null,
    editPersonID: null,
    editPersonName: null,
    editRelationshipType: null,
    editFirstPersonRelationshipID: null,
    editSecondPersonRelationshipID: null,

    visitedPeople: [],
    unassignedVisitedPeople: [],
    relationships: [],
    location_id: "",
    visitable_types_of_people: [],

    selectedEventVisitedPersonId: null,
    selectedTypedName: null,

    loaded: false,
    visitedPeopleLoaded: false,
    unassignedVisitedPeopleLoaded: false,
    relationshipsLoaded: false,
    unauthorized: "",
    error: ""
  };

  handleNewRelationshipPanel(event) {
    let person_id = event.currentTarget.id;
    let person = this.state.visitedPeople.filter(visitedPerson => visitedPerson.visited_person_id.toString() === person_id)[0]
    this.setState({
      newPanelToggle: true,
      newPersonID: person_id,
      newPersonName: `${person.first_name} ${person.last_name}`
    });

    event.preventDefault();
    event.stopPropagation();
  }

  handleEditRelationshipPanel(event) {
    let person_id = event.currentTarget.id;

    const relationship = this.state.relationships.find(relationship => (relationship.person_id === parseInt(person_id)))

    this.setState({
      editID: relationship.relationship_id,
      editPersonID: person_id,
      editPersonName: relationship.full_name,
      editRelationshipType: relationship.relationship_type,
      editFirstPersonRelationshipID: relationship.id,
      editSecondPersonRelationshipID: relationship.other_person_relationship_id,
      editPanelToggle: true,
    });

    event.preventDefault();
    event.stopPropagation();
  }

  closeNewRelationshipPanel() {
    this.setState({
      newPanelToggle: false,
      newPersonID: null,
      newPersonName: null
    });
    this.componentDidMount();
  }

  closeEditRelationshipPanel() {
    this.setState({
      editPanelToggle: false,
      editID: null,
      editPersonID: null,
      editFirstPersonRelationshipID: null,
      editSecondPersonRelationshipID: null,
      editRelationshipType: null,
    });
    this.componentDidMount();
  }


  showPhoto(event) {
    const name = event.currentTarget.name === "arriving" ? "showArrivingPhoto" : "showLeavingPhoto";

    this.setState({ [name]: true });

    event.preventDefault();
  }


  showCarenoteData(event) {
    const identifier = event.currentTarget.name;
    let carenote_data = null;

    if (identifier === "arriving" || identifier === "leaving") {
      carenote_data = this.state[identifier + "_carenotes"]
    }
    else {
      const [index, direction] = identifier.split("-")
      carenote_data = this.state.visitedPeople[index][direction + "_carenotes"]
    }

    this.setState({ displayCarenoteData: carenote_data });

    event.preventDefault();
  }

  hideCarenoteData(event) {
    this.setState({ displayCarenoteData: null })
  }

  emptyVisitedPeople() {
    if (this.state.visitedPeople.length === 0){
      return (
        <div className="long-tr">
          <div className="long-td">
            There are no visited people recorded.
          </div>
        </div>
      )
    }
  }

  formatDate(dateString) {
    let date = dateString.split(" ")[0];
    let [day, month, year] = date.split("/");
    return new Date(`${year}/${month}/${day}`)

  }

  handleManualCheckout(data) {
    this.setState({
      person_id: data.person_id,
      person_name: data.person_name,
      type_of_person: data.type_of_person,
      check_in: data.check_in,
      check_out: data.check_out,
      reported_check_out: data.reported_check_out,
      location: data.location,
      arriving_carenotes: data.arriving_carenotes,
      leaving_carenotes: data.leaving_carenotes,
      photo: data.photo
    })
  }

  renderManualCheckOutButton() {
    if (this.state.check_out === "N/A" && this.state.reported_check_out === "N/A" && this.state.cool_event === false) {
      return <ManualCheckOutButton className="top-padding" event_id={this.props.id} check_in_date={this.formatDate(this.state.check_in)} successCallback={this.handleManualCheckout} />
    }
  }

  viewPhoto(direction) {
    const photo = direction === "arriving" ? this.state.arriving_photo_url : this.state.leaving_photo_url;
    const showPhoto = direction === "arriving" ? this.state.showArrivingPhoto : this.state.showLeavingPhoto;

    if (photo) {
      if (showPhoto) {
        return (
          <div className="centered-image top-padding">
            <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${photo}`} alt={`Taken when ${direction}`}></img>
          </div>
        )
      }
      else {
        return (
          <div className="centered-image top-padding">
            <button className="button" name={direction} onClick={this.showPhoto}>Show {direction} photo</button>
          </div>
        )
      }
    }
  }

  renderUnassignedVisitedPeople(unassignedVisitedPeople) {
    if (unassignedVisitedPeople.length > 0) {
      return (
        <>
          <div className="row small-top-padding">
            <label className="column">Unassigned Visited people:</label>
          </div>
          <div className="table visited-people-table">
            <div className="tr heading">
              <div className="th">Typed name</div>
              <div className="th">Assign Person?</div>
            </div>
            {unassignedVisitedPeople.map((eventVisitedPerson) => (
              <div className="tr" key={eventVisitedPerson.id} id={eventVisitedPerson.id}>
                <div className="td">{eventVisitedPerson.typed_name}</div>
                <div className="td with-button">
                  { this.state.cool_event === false ?
                    <div className="small button" onClick={this.handleAssignVisitor} id={eventVisitedPerson.typed_name}>Assign Person</div> :
                    <div className="small button" disabled={true} id={eventVisitedPerson.typed_name}>This person cannot be assigned</div>
                  }
                </div>
              </div>
            ))}
          </div>
          {this.renderAssignVisitedPeopleModal()}
        </>
      )
    }
  }

  renderAssignVisitedPeopleModal() {
    if (this.state.selectedEventVisitedPersonId && this.state.selectedTypedName) {
      return (
        <AssignVisitedPeopleModal
          event_id={this.props.id}
          typed_name={this.state.selectedTypedName}
          person_name={this.state.person_name}
          event_visited_person_id={this.state.selectedEventVisitedPersonId}
          visitable_types_of_people={this.state.visitable_types_of_people}
          location_id={this.state.location_id}
          profile_id={this.state.profile_id}
          profile_name={this.state.profile_name}
          handleClose={() => {
            this.setState({ selectedTypedName: null, selectedEventVisitedPersonId: null });
            this.componentDidMount();
          }}
        />
      )
    }
  }

  renderCustomAttributes() {
    return this.state.custom_attributes.map((custom_attribute) => (
      <div key={custom_attribute.id} className="tr">
        <label className="pcolumn strong">{custom_attribute.name}:</label>
        <label className="prightcolumn">{custom_attribute.value}</label>
      </div>
    ))
  }

  renderRelationshipButton(visitedPerson) {
    if (this.state.relationships.find(relationship => relationship.person_id === visitedPerson.visited_person_id)) {
      return (
        <div className="small button" onClick={this.handleEditRelationshipPanel} id={visitedPerson.visited_person_id}>Update Relationship</div>
      )
    }

    return (
      <div className="small button" onClick={this.handleNewRelationshipPanel} id={visitedPerson.visited_person_id}>Create Relationship</div>
    )
  }

  handleAssignVisitor(event) {
    let selectedEventVisitedPersonId = event.currentTarget.parentNode.parentNode.id;
    let selectedTypedName = event.currentTarget.id;

    this.setState({
      selectedEventVisitedPersonId: selectedEventVisitedPersonId,
      selectedTypedName: selectedTypedName
    })
  }

  renderCarenotesInfo(carenotes, name) {
    if (carenotes.length === 0) {
      return <FontAwesomeIcon icon={faTimes} />
    }

    let text = carenotes.filter(carenote => carenote.response?.status === 200).length > 0 ? "Yes" : "No"

    return <button className="button" name={name} onClick={this.showCarenoteData}>{text}</button>
  }

  renderCoolEventNotice() {
    if (this.state.cool_event === false || ((this.state.check_out !== "N/A" || this.state.reported_check_out !== "N/A") && this.state.unassignedVisitedPeople.length === 0)) {
      return null
    }

    let text = "This event is over 3 months old. It"

    if (this.state.check_out === "N/A" && this.state.reported_check_out === "N/A") {
      text += " cannot be manually checked out"
    }

    if (this.state.check_out === "N/A" && this.state.reported_check_out === "N/A" && this.state.unassignedVisitedPeople.length > 0) {
      text += " and it"
    }

    if (this.state.unassignedVisitedPeople.length > 0) {
      text += "s manually typed visited people cannot be assigned"
    }

    text += "."

    return <NoticeBox type="info" text={text} />
  }

  render() {
    const { person_id, person_name, type_of_person, check_in, check_out, reported_check_out, location, arriving_carenotes, leaving_carenotes, arriving_photo_taken, leaving_photo_taken, displayCarenoteData, newPersonID, newPersonName, editID, editPersonID, editPersonName, editFirstPersonRelationshipID, editSecondPersonRelationshipID, editRelationshipType, loaded, unauthorized, error, visitedPeople, visitedPeopleLoaded, relationshipsLoaded, unassignedVisitedPeople, unassignedVisitedPeopleLoaded } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || visitedPeopleLoaded === false || unassignedVisitedPeopleLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && visitedPeopleLoaded && relationshipsLoaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <SetTitle title={`View Event | Exports`} />
          <PopupBox />

          <h3 className="event-title">Event View</h3>

          {this.renderCoolEventNotice()}

          <form className="settings big-settings">
            <div className="table soft-table">

              <div className="tr">
                <label className="pcolumn strong">Name:</label>
                <Link to={`/people/${person_id}`}><label className="prightcolumn link">{person_name}</label></Link>
              </div>

              {this.renderCustomAttributes()}

              <div className="tr">
                <label className="pcolumn strong">Arrived:</label>
                <label className="prightcolumn">{check_in}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Left:</label>
                <label className="prightcolumn">{check_out}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Reported Left:</label>
                <label className="prightcolumn">
                {reported_check_out}
                <HelpText className="tr-helptext inline" page={'event'} section={'reported_check_out'} />
                </label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Location:</label>
                <label className="prightcolumn"> {location}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Type of person:</label>
                <label className="prightcolumn"> {type_of_person}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Arriving carenote created?</label>
                <label className="prightcolumn"> {arriving_carenotes.length > 0 ? <button className="button" name="arriving" onClick={this.showCarenoteData}>Yes</button> : <FontAwesomeIcon icon={faTimes} />}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Leaving carenote created?</label>
                <label className="prightcolumn"> {leaving_carenotes.length > 0 ? <button className="button" name="leaving" onClick={this.showCarenoteData}>Yes</button> : <FontAwesomeIcon icon={faTimes} />}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Arriving photo taken?</label>
                <label className="prightcolumn"> {arriving_photo_taken? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</label>
              </div>

              <div className="tr">
                <label className="pcolumn strong">Leaving photo taken?</label>
                <label className="prightcolumn"> {leaving_photo_taken? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</label>
              </div>
              {this.viewPhoto("arriving")}
              {this.viewPhoto("leaving")}
            </div>

            {this.renderManualCheckOutButton()}

            {baseLicense && (
              <>
                <br></br>
                <div className="row">
                  <label className="column">Visited people:</label>
                </div>
                <div className="table visited-people-table">
                  <div className="tr heading">
                    <div className="th">First name</div>
                    <div className="th">Last name</div>
                    <div className="th">Arriving carenote created?</div>
                    <div className="th">Leaving carenote created?</div>
                    <div className="th" />
                  </div>
                  {visitedPeople.map((visitedPerson, index) => (
                    <Link to={`/people/${visitedPerson.visited_person_id}`} className="tr" key={`${visitedPerson.visited_person_id}-${index}`}>
                      <div className="td">{visitedPerson.first_name}</div>
                      <div className="td">{visitedPerson.last_name}</div>
                      <div className="td">{this.renderCarenotesInfo(visitedPerson.arriving_carenotes, `${index}-arriving`)}</div>
                      <div className="td">{this.renderCarenotesInfo(visitedPerson.leaving_carenotes, `${index}-leaving`)}</div>
                      <div className="td with-button">{this.renderRelationshipButton(visitedPerson)}</div>
                    </Link>
                  ))}
                </div>
                {this.emptyVisitedPeople()}

                {this.renderUnassignedVisitedPeople(unassignedVisitedPeople)}
              </>
            )}
          </form>

          <Audits klass="Event" id={this.props.id} />

          <SlidingPane isOpen={this.state.newPanelToggle} title="New Relationship" width="50%"
            onRequestClose={() => {
              this.setState({
                newPanelToggle: false,
                newPersonID: ""
              });
              this.componentDidMount();
            }}>
            <CreateRelationship other_person_name={person_name} other_person_id={person_id.toString()} person_id={newPersonID} person_name={newPersonName} closeNewPanel={this.closeNewRelationshipPanel} />
          </SlidingPane>

          <SlidingPane isOpen={this.state.editPanelToggle} title="Update Relationship" width="50%"
            onRequestClose={this.closeEditRelationshipPanel}>
            <UpdateRelationship id={editID} other_person_name={person_name} other_person_id={person_id.toString()} person_id={editPersonID} person_name={editPersonName} first_person_relationship_id={editFirstPersonRelationshipID} second_person_relationship_id={editSecondPersonRelationshipID} relationship_type={editRelationshipType} closeEditPanel={this.closeEditRelationshipPanel} />
          </SlidingPane>

          <ModalContainerTrigger full={true} showModal={displayCarenoteData !== null} closeModal={this.hideCarenoteData}>
            <div>
              {displayCarenoteData !== null && displayCarenoteData.map((carenote, index) =>
                <div key={index}>
                  <h3>Carenote Request Info</h3>
                  <div><strong>Request URL:</strong></div>
                  <div>{carenote.request?.url}</div>
                  <div><strong>Request body:</strong></div>
                  {JSON.stringify(carenote.request?.body, null, 2).split("\n").map((line, index) =>
                    <div key={index}>{line}</div>
                  )}
                  <div><strong>Sent at:</strong></div>
                  <div>{carenote.request?.time}</div>
                  <br/>
                  <div><strong>Response status:</strong></div>
                  <div>{carenote.response?.status}</div>
                  <div><strong>Response body:</strong></div>
                  {JSON.stringify(carenote.response?.body, null, 2).split("\n").map((line, index) =>
                    <div key={index}>{line}</div>
                  )}
                  <div><strong>Received at:</strong></div>
                  <div>{carenote.response?.time}</div>
                </div>
              )}
            </div>
          </ModalContainerTrigger>
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          person_id: data.person_id,
          person_name: data.person_name,
          custom_attributes: data.custom_attributes,
          type_of_person: data.type_of_person,
          check_in: data.check_in,
          check_out: data.check_out,
          reported_check_out: data.reported_check_out,
          location: data.location,
          arriving_carenotes: data.arriving_carenotes,
          leaving_carenotes: data.leaving_carenotes,
          arriving_photo_taken: data.arriving_photo_taken,
          leaving_photo_taken: data.leaving_photo_taken,
          arriving_photo_url: data.arriving_photo_url,
          leaving_photo_url: data.leaving_photo_url,
          cool_event: data.cool_event,
          profile_id: data.profile_id,
          profile_name: data.profile_name,
          loaded: true
        })
        fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${data.person_id}/relationships`, requestOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            }
            else if (response.status === 401) {
              this.setState({error: JSON.stringify(response.body)})
              this.setState({unauthorized: true})
            }
            else {
              throw new Error('Something went wrong ...');
            }
          })
          .then(data => {
            this.setState({ relationships: data, relationshipsLoaded: true })
          })
          .catch(error => this.setState({ error, loaded: true }))
      })
      .catch(error => this.setState({ error, loaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events/${id}/visited_people`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ visitedPeople: data, visitedPeopleLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events/${id}/unassigned_visited_people`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          unassignedVisitedPeople: data.event_visited_people,
          visitable_types_of_people: data.visitable_types_of_people,
          location_id: data.location_id,

          unassignedVisitedPeopleLoaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default ViewEvent;
