import React, { Component } from 'react';
import Header from '../../components/header/Header';
import ListOfFaqs from '../../components/support/ListOfFaqs';
import ListOfTickets from '../../components/support/ListOfTickets';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

class SupportIndex extends Component {
  render() {
    const { match: { params } } = this.props;

    return (
      <div>
        <SetTitle title={"Support"} />
        <Header />

        <div className="main-page">
          <h2 className="page-title">Support</h2>
          <OrganisationName />

          <div>
            <div className="support-resources">
              <h3>Help Section</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/quick-start-guide.pdf'>Quick Start Guide</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/link-to-mobile-care-monitoring.pdf'>Linking to Mobile Care Monitoring PDF</a>
            </div>
            <div className="support-resources">
              <h3>Useful guides</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/managing-people'>Managing people</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/staff-time-management'>Staff time management</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/fire-log'>Firelog</a>
            </div>
            <div className="support-resources">
              <h3>Videos</h3>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/videos/training-video'>Training video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/link-to-mobile-care-monitoring-video'>Linking to Mobile Care Monitoring Video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/interactive-android-device-setup-video'>Interactive Android Device Setup Video</a>
              <p></p>
              <a target="_blank" rel="noopener noreferrer" href='https://www.dreception.com/docs/interactive-apple-device-setup-video'>Interactive Apple Device Setup Video</a>
            </div>
          </div>
          <div>
            <ListOfFaqs id={params.faq_id} />
            <ListOfTickets id={params.id} />
          </div>
        </div>

      </div>
    );
  }
}

export default SupportIndex;
