import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import OrganisationName from '../../components/shared/OrganisationName';
import SetTitle from '../../components/shared/SetTitle';

import UpdateOrganisation from '../../components/organisations/UpdateOrganisation';
import UpdateFireLogSettings from '../../components/organisations/UpdateFireLogSettings';
import UpdatePlannedEventSettings from '../../components/organisations/UpdatePlannedEventSettings';
import ListOfUsers from '../../components/users/ListOfUsers';
import MobileEnrolment from '../../components/settings/MobileEnrolment';
import TidyUp from '../../components/settings/TidyUp';

import PopupBox from '../../components/shared/PopupBox';

import { adminUser, managerUser, displayStaffFulfilment } from '../../utilities/Forms.js'

import BtnOrganisation from '../../images/btn_organisation_1075_725.png';
import BtnUsers from '../../images/btn_users_1075_725.png';
import BtnFireLog from '../../images/btn_fire_log_1075_725.png';
import BtnPlannedEvents from '../../images/btn_planned_events_1075_725.png';
import EnrolmentIcon from '../../images/enrolment.png';
import TidyUpIcon from '../../images/tidy_up.png';

class SettingsIndex extends Component {
  constructor(props) {
    super(props);

    this.updatePopupCallback = this.updatePopupCallback.bind(this);
  }

  state = {
    page: "index"
  };

  currentPage(page) {
    if (page === this.state.page) {
      return "current-page"
    }
  }

  updatePopupCallback(id) {
    this.setState({
      resetId: id
    })
  }

  renderSettingsIndex() {
    const baseLicense = localStorage.license === "base"

    if (this.state.page === "index") {
      if (adminUser() || managerUser()) {
        return (
          <div>
            <SetTitle title={"Settings"} />
            <h2 className="page-title">Settings</h2>
            <OrganisationName />

            <div>
              <p>Welcome to your settings page. Below you can select different types of settings you may want to explore.</p>
            </div>

            {adminUser() && (
                <Link to={"/settings/organisation"}>
                  <div className="widget exports-page" style={{ backgroundImage: `url(${BtnOrganisation})`}}>
                    <h3 className="title">Organisation Settings</h3>
                  </div>
                </Link>
              )}

            {baseLicense && (
              <Link to={"/settings/fire-log"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnFireLog})`}}>
                  <h3 className="title">Fire Log Settings</h3>
                </div>
              </Link>
            )}

            {baseLicense && (
              <Link to={"/settings/planned-events"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnPlannedEvents})`}}>
                  <h3 className="title">Planned Event Settings</h3>
                </div>
              </Link>
            )}

            {(adminUser() || managerUser()) && (
              <Link to={"/settings/users"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${BtnUsers})`}}>
                  <h3 className="title">User Settings</h3>
                </div>
              </Link>
            )}

            {(baseLicense && displayStaffFulfilment()) && (
              <Link to={"/settings/enrolment"}>
                <div className="widget exports-page" style={{ backgroundImage: `url(${EnrolmentIcon})`}}>
                  <h3 className="title">Mobile Enrolment</h3>
                </div>
              </Link>
            )}

            {adminUser() && (
                <Link to={"/settings/tidy-up"}>
                  <div className="widget exports-page" style={{ backgroundImage: `url(${TidyUpIcon})`}}>
                    <h3 className="title">Data Tidy Up</h3>
                  </div>
                </Link>
              )}
          </div>
        )
      }
      else {
        return (
          <div>
            <SetTitle title={"Settings"} />
            <h2 className="page-title">Settings</h2>
            <OrganisationName />

            <div>
              <p>You do not have access to edit core settings. To change your access level, please contact one of your administrators.</p>
            </div>
          </div>
        )
      }
    }
  }

  renderPages() {
    switch (this.state.page) {
      case "organisation":
        return (
          <div>
            <SetTitle title={"Organisation Settings | Settings"} />
            <h2 className="page-title">Organisation Settings</h2>
            <OrganisationName />
            <PopupBox />
            <UpdateOrganisation updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      case "users":
        return (
          <div>
            <SetTitle title={"User Settings | Settings"} />
            <h2 className="page-title">User Settings</h2>
            <OrganisationName />
            <PopupBox />
            <ListOfUsers userId={this.props.match.params.user_id} updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      case "fire-log":
        return (
          <div>
            <SetTitle title={"Fire Log Settings | Settings"} />
            <h2 className="page-title">Fire Log Settings</h2>
            <OrganisationName />
            <PopupBox />
            <UpdateFireLogSettings updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      case "planned-events":
        return (
          <div>
            <SetTitle title={"Planned Event Settings | Settings"} />
            <h2 className="page-title">Planned Event Settings</h2>
            <OrganisationName />
            <PopupBox />
            <UpdatePlannedEventSettings updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      case "enrolment":
        return (
          <div>
            <SetTitle title={"Mobile Enrolment | Settings"} />
            <h2 className="page-title">Mobile Enrolment</h2>
            <OrganisationName />
            <PopupBox />
            <MobileEnrolment updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      case "tidy-up":
        return (
          <div>
            <SetTitle title={"Data Tidy Up | Settings"} />
            <h2 className="page-title">Data Tidy Up</h2>
            <OrganisationName />
            <PopupBox />
            <TidyUp updatePopupCallback={this.updatePopupCallback} />
          </div>
        );
      default:
        return this.renderSettingsIndex()
    }
  }

  render() {
    const baseLicense = localStorage.license === "base"

    return (
      <div>
        <Header />

        <div className="settings-page">
          <div className="sidebar">
            <Link to={"/settings"} className={this.currentPage("index")}>List of Settings</Link>
            {adminUser() && (<Link to={"/settings/organisation"} className={this.currentPage("organisation")}>Organisation Settings</Link>)}
            {baseLicense && (<Link to={"/settings/fire-log"} className={this.currentPage("fire-log")}>Fire Log Settings</Link>)}
            {baseLicense && (<Link to={"/settings/planned-events"} className={this.currentPage("planned-events")}>Planned Event Settings</Link>)}
            {(adminUser() || managerUser()) && (<Link to={"/settings/users"} className={this.currentPage("users")}>User Settings</Link>)}
            {(baseLicense && displayStaffFulfilment()) && (<Link to={"/settings/enrolment"} className={this.currentPage("enrolment")}>Mobile Enrolment</Link>)}
            {adminUser() && (<Link to={"/settings/tidy-up"} className={this.currentPage("tidy-up")}>Data Tidy Up</Link>)}
          </div>
          <div className="main-content">
            <div className="main-page">
              {this.renderPages()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    // Reporting, and Emergency can't edit their settings yet
    // Managers can visit Planned Events and Mobile Enrolment settings
    if (!adminUser() && !managerUser()) {
      return {
        page: "index"
      }
    }

    // Render sidebar page based on URL
    if (props.history.location.pathname.endsWith("/organisation")) {
      return {
        page: "organisation"
      }
    }
    else if (props.history.location.pathname.includes("/fire-log")) {
      return {
        page: "fire-log"
      }
    }
    else if (props.history.location.pathname.includes("/planned-events")) {
      return {
        page: "planned-events"
      }
    }
    else if (props.history.location.pathname.includes("/users")) {
      return {
        page: "users"
      }
    }
    else if (props.history.location.pathname.includes("/enrolment")) {
      return {
        page: "enrolment"
      }
    }
    else if (props.history.location.pathname.includes("/tidy-up")) {
      return {
        page: "tidy-up"
      }
    }
    else {
      return {
        page: "index"
      }
    }
  }
}

export default SettingsIndex;
