import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';

import { adminUser, managerUser, renderErrorWarning, capitalize } from '../../utilities/Forms.js'
import { renderFullName } from '../../utilities/Generic.js'

import DefaultAvatar from '../../images/default_avatar.jpg';

class CreatePerson extends Component {

  constructor(props) {
    super(props);

    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.clearImageUpload = this.clearImageUpload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    alternate_name: "",
    display_name: "",
    phone: "",
    email: "",
    type_of_person: "",
    location_ids: [],
    role_ids: [],
    tag_ids: [],
    covid_pass_exemption: false,
    redlist: false,
    contracted_hours: "",
    visit_all: "",
    picture: null,
    upload_file: null,

    locationsLoaded: false,
    rolesLoaded: false,
    tagsLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event, targetKey, ids = true) {
    const name = ids ? parseInt(event.target.name) : event.target.name;
    const value = event.target.checked;

    let array = this.state[targetKey]

    if (value === true) {
      if (!array.includes(name)) {
        array.push(name)
      }
    }
    else if (value === false) {
      if (array.includes(name)) {
        let index = array.indexOf(name)
        array.splice(index, 1)
      }
    }

    this.setState({[targetKey]: array});
  }

  handleFileUpload(event) {
    let name = event.target.name;
    let value = event.target.files[0];

    this.setState({[name]: value});
  }

  clearImageUpload(event) {
    this.setState({'picture': null});
  }

  handleSubmit(event) {
    const formData = new FormData()
    formData.append('person[first_name]', this.state.first_name)
    formData.append('person[last_name]', this.state.last_name)
    formData.append('person[alternate_name]', this.state.alternate_name)
    formData.append('person[display_name]', this.state.display_name)
    formData.append('person[phone]', this.state.phone)
    formData.append('person[email]', this.state.email)
    formData.append('person[type_of_person]', this.state.type_of_person)
    formData.append('person[covid_pass_exemption]', this.state.covid_pass_exemption)
    formData.append('person[redlist]', this.state.redlist)
    formData.append('person[contracted_hours]', this.state.contracted_hours)
    formData.append('person[visit_all]', this.state.visit_all)

    this.state.location_ids.map((location_id, index) => (
      formData.append(`person[location_ids][]`, location_id)
    ))

    this.state.role_ids.map((role_id, index) => (
      formData.append(`person[role_ids][]`, role_id)
    ))

    this.state.tag_ids.map((tag_id, index) => (
      formData.append(`person[tag_ids][]`, tag_id)
    ))

    if (this.state.upload_file !== null) {
      formData.append('person[picture]', this.state.upload_file)
    }

    var requestOptions = {
      method: 'POST',
      body: formData,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Created!
        </div>
      );
    }
  }

  renderPersonPicture(picture) {
    if (picture === null) {
      return (
        <>
          <img src={DefaultAvatar} style={{maxWidth: "100%"}} alt="Placeholder"/>
          <input className="column" type="file" name="upload_file" onChange={this.handleFileUpload} />
        </>
      )
    }
    else {
      return (
        <div className="inline-image">
          <img src={`${process.env.REACT_APP_ROOT_DOMAIN}/${picture}`} alt={`${this.state.full_name}`}></img>
          <button onClick={this.clearImageUpload}>Clear image</button>
        </div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderLocationMessage() {
    // Notify Manager users that they will not be able to see the Person if they don't assign a location.
    // This is because we only show People assigned to the Managers locations on the Index page
    if (managerUser() && this.state.location_ids.length === 0) {
      return (
        <NoticeBox type="info" text="You must select a Location, otherwise this Person will be hidden from view." />
      )
    }
  }

  renderRolesForm() {
    const { type_of_person, role_ids, roles } = this.state;

    if (type_of_person === "staff" || type_of_person === "contractor") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'roles'} />

          <label className="column">Roles:</label>{ this.renderErrors('role') }{ this.renderErrors('roles') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Add person to?</th>
              </tr>
              {roles.map((role) => (
                <tr key={role.id}>
                  <td>
                    <Link to={`/configuration/roles/${role.id}`}>{role.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={role.name} name={role.id} checked={role_ids.includes(role.id)} onChange={(event) => this.handleMultiCheckbox(event, "role_ids")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
    else {
      return(this.renderErrors('role'))
    }
  }

  renderTagsForm() {
    const { tag_ids, tags } = this.state;

    if (localStorage.license === "base") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'tags'} />

          <label className="column">Tags:</label>{ this.renderErrors('tag') }{ this.renderErrors('tags') }
          <table className="checkbox-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Assign tag</th>
              </tr>
              {tags.map((tag) => (
                <tr key={tag.id}>
                  <td>
                    <Link to={`/configuration/tags/${tag.id}`}>{tag.name}</Link>
                  </td>
                  <td className="center-cell">
                    <input className="column" type="checkbox" data-name={tag.name} name={tag.id} checked={tag_ids.includes(tag.id)} onChange={(event) => this.handleMultiCheckbox(event, "tag_ids")} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }

  renderContractedHoursForm() {
    const { type_of_person, contracted_hours } = this.state;

    if (type_of_person === "staff" || type_of_person === "contractor") {
      return (
        <div className="row">
          <HelpText page={'person'} section={'contracted_hours'} />

          <label className="column">Weekly Contracted Hours:</label>{ this.renderErrors('contracted_hours') }
          <input className="column" type="number" step="0.1" min="0" max="168" name="contracted_hours" value={contracted_hours} onChange={this.handleChange} />
        </div>
      )
    }
  }

  gridColumns(count) {
    var output = ""

    for (var i = 0; i < count; i++) {
      output += "1fr "
    }

    return output
  }

  render() {
    const { first_name, last_name, alternate_name, display_name, phone, email, type_of_person, covid_pass_exemption, location_ids, locations, redlist, visit_all, picture, locationsLoaded, rolesLoaded, tagsLoaded, id, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/people/${id}`}/>
    }

    if (locationsLoaded === false || rolesLoaded === false || tagsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (locationsLoaded && rolesLoaded && tagsLoaded) {
      const baseLicense = localStorage.license === "base"

      let display_names = [first_name]

      if (alternate_name) {
        display_names = display_names.concat(alternate_name.split(","))
      }

      return (
        <div>
          <SetTitle title={"New Person | People"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="two-columns">
              <div className="column">
                <div className="row">
                  <HelpText page={'person'} section={'firstName'} />

                  <label className="column">First Name:</label>{ this.renderErrors('required') }
                  <input className="column" type="text" name="first_name" value={first_name} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'lastName'} />

                  <label className="column">Last Name:</label>{ this.renderErrors('required') }
                  <input className="column" type="text" name="last_name" value={last_name} onChange={this.handleChange} />
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'alternateName'} />

                  <label className="column">Alternate Name:</label>{ this.renderErrors('required') }
                  <input className="column" type="text" name="alternate_name" value={alternate_name} onChange={this.handleChange} />
                </div>
              </div>
              <div className="column">
                {this.renderPersonPicture(picture)}
              </div>
            </div>

            <div className="row">
              <HelpText page={'person'} section={'displayName'} />

              <label className="column">Display Name</label>{ this.renderErrors('display_name') }

              <div style={{ display: "grid",gridTemplateColumns: this.gridColumns(display_names.length + 1)}}>
                {display_names.map((display_name_option, index) => (
                  <div className="question-type" key={index}>
                    <input className="column" type="radio" name="display_name" id={index} value={display_name_option} checked={display_name === display_name_option} onChange={this.handleChange} />
                    <label htmlFor="boolean">{display_name_option}</label>
                  </div>
                ))}

                <div className="question-type">
                  <input className="column" type="radio" name="display_name" id="" value="" checked={display_name === ""} onChange={this.handleChange} />
                  <label htmlFor="text"><em>None</em></label>
                </div>
              </div>
            </div>

            <NoticeBox type="info">
              <HelpText page={'person'} section={'namePreview'} />

              <label className="column">Their name will display as: {renderFullName({first_name: first_name, alternate_name: alternate_name, display_name: display_name, last_name: last_name})}</label>{ this.renderErrors('required') }
            </NoticeBox>

            <div className="row">
              <HelpText page={'person'} section={'phone'} />

              <label className="column">Phone:</label>{ this.renderErrors('required') }
              <input className="column" type="text" name="phone" value={phone} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'person'} section={'email'} />

              <label className="column">Email:</label>{ this.renderErrors('required') }
              <input className="column" type="email" name="email" value={email} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'person'} section={'type'} />

              <label className="column">Type:</label>{ this.renderErrors('type_of_person') }
              <select name="type_of_person" onChange={this.handleChange} value={type_of_person}>
                <option value="">Select</option>
                <option value="visitor">Visitor</option>
                {baseLicense && (
                  <>
                    <option value="resident">Resident</option>
                    <option value="staff">Staff</option>
                    <option value="contractor">Contractor</option>
                    <option value="industry professional">Industry Professional</option>
                    <option value="other">Other</option>
                  </>
                )}
              </select>
            </div>

            <div className="row">
              <HelpText page={'person'} section={'locations'} />

              <label className="column">Location:</label>{ this.renderErrors('location') }
              { this.renderLocationMessage() }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Add person to?</th>
                  </tr>
                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" data-name={location.name} name={location.id} checked={location_ids.includes(location.id)} onChange={(event) => this.handleMultiCheckbox(event, "location_ids")} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {this.renderRolesForm()}
            {this.renderTagsForm()}
            {this.renderContractedHoursForm()}

            {baseLicense && (
              <>
                <div className="row">
                  <HelpText page={'person'} section={'covid_pass_exemption'} />

                  <input className="column" type="checkbox" name="covid_pass_exemption" id="covid_pass_exemption_checkbox" checked={covid_pass_exemption} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="covid_pass_exemption_checkbox">Exempt from Covid vaccination?</label>{ this.renderErrors('covid_pass_exemption') }
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'redlist'} />

                  <input className="column" type="checkbox" name="redlist" id="redlist_checkbox" checked={redlist} onChange={this.handleCheckbox} />
                  <label className="column checkbox-label" htmlFor="redlist_checkbox">Email warning upon arrival?</label>{ this.renderErrors('redlist') }
                </div>

                <div className="row">
                  <HelpText page={'person'} section={'visit_all'} />

                  <label className="column">Allow {capitalize(type_of_person)} to Visit All:</label>{ this.renderErrors('visit_all') }
                  <select name="visit_all" onChange={this.handleChange} value={visit_all}>
                    <option value="">None</option>
                    <option value="visitor">Visitors</option>
                    <option value="resident">Residents</option>
                    <option value="staff">Staff</option>
                    <option value="contractor">Contractors</option>
                    <option value="industry professional">Industry Professionals</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </>

            )}

            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>
        </div>
      );
    }
  }

  componentDidMount() {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    if (localStorage.license === "base") {
      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ roles: data, rolesLoaded: true })
        })
        .catch(error => this.setState({ error, rolesLoaded: true }))

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/tags/names_and_ids`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ tags: data, tagsLoaded: true })
        })
        .catch(error => this.setState({ error, tagsLoaded: true }))
    }
    else {
      this.setState({
        roles: [],
        tags: [],
        rolesLoaded: true,
        tagsLoaded: true
      })
    }
  }
}

export default CreatePerson;
