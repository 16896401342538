import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import NoticeBox from '../../components/shared/NoticeBox';
import HelpText from '../../components/help/HelpText';

import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { capitalize, adminUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import Audits from '../../components/shared/Audits';

class UpdateUser extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleApiKeys = this.toggleApiKeys.bind(this);
    this.confirmPasswordReset = this.confirmPasswordReset.bind(this);
    this.cancelPasswordReset = this.cancelPasswordReset.bind(this);
    this.requestPasswordReset = this.requestPasswordReset.bind(this)
    this.requestPasswordResetLink = this.requestPasswordResetLink.bind(this)
    this.cycleApiKeys = this.cycleApiKeys.bind(this);
  }

  state = {
    first_name: "",
    last_name: "",
    email: "",
    access_levels: [],
    external_sign_on_only: false,
    receive_critical_alerts: true,
    location_ids: [],
    fire_log_location_ids: [],
    customer_api: false,
    locations: [],

    passwordResetConfirmationModal: false,
    resettingPassword: false,
    resetPasswordLink: "",

    updated: false,
    loaded: false,
    locationsLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = ""
    const useInteger = event.target.dataset.useInteger;

    if (useInteger === "true") {
      name = parseInt(event.target.name);
    }
    else {
      name = event.target.name;
    }

    const value = event.target.checked;
    const key = event.target.dataset.key;

    var state = this.state[key]

    if (value === true) {
      if (!state.includes(name)) {
        state.push(name)
      }
    }
    else if (value === false) {
      if (state.includes(name)) {
        const index = state.indexOf(name)
        state.splice(index, 1)
      }
    }

    this.setState({key: state});

    if (this.state.current_user === true && localStorage.getItem("access_levels").includes("admin")) {
      if (name === "admin" && value === false) {
        this.setState({"errors": {"access_level": ["You cannot remove Admin access from yourself"]}});
      }
      else if (name === "admin" && value === true) {
        this.setState({"errors": ""});
      }
    }
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "user": {
        "first_name": this.state.first_name,
        "last_name": this.state.last_name,
        "email": this.state.email,
        "access_level": this.state.access_levels,
        "external_sign_on_only": this.state.external_sign_on_only,
        "receive_critical_alerts": this.state.receive_critical_alerts,
        "location_ids": this.state.location_ids,
        "fire_log_location_ids": this.state.fire_log_location_ids,
        "inactive": this.state.inactive
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ updated: true, errors: "" })
        sessionStorage.setItem("updateSuccess", "User updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  requestPasswordReset(event) {
    this.setState({resettingPassword: true})
    this.setState({passwordErrors: ""})
    this.setState({passwordError: ""})

    var headers = new Headers();
    headers.append("Content-Type", "application/json");


    var json = JSON.stringify({
      "password_reset": {
        "email_address": this.state.saved_email,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/request_password_reset`, requestOptions)
    .then(response => {
      this.setState({resettingPassword: false})

      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ passwordErrors: data })
      } else {
        this.setState({
          passwordResetConfirmationModal: false,
          passwordErrors: ""
        })

        sessionStorage.setItem("updateSuccess", "Password Reset Email requested successfully!");
        this.props.updatePopupCallback(this.props.id);
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  requestPasswordResetLink(event) {
    let id = this.props.id;

    this.setState({resettingPassword: true})
    this.setState({passwordErrors: ""})
    this.setState({passwordError: ""})

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/${id}/request_password_reset_link`, requestOptions)
    .then(response => {
      this.setState({resettingPassword: false})

      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ passwordErrors: data })
      } else {
        this.setState({
          resetPasswordLink: data.reset_link
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  confirmPasswordReset(event) {
    this.setState({
      passwordResetConfirmationModal: true,
      passwordError: "",
      passwordErrors: ""
    })
  }

  cancelPasswordReset() {
    this.setState({
      passwordResetConfirmationModal: false
    })
  }

  toggleApiKeys(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});


    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/${this.props.id}/toggle_api_keys`, requestOptions)
    .then(response => {
      this.setState({resettingPassword: false})

      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({
          customer_api: data.customer_api,
          primary_key: data.primary_key,
          secondary_key: data.secondary_key,
          primary_key_valid_until: data.primary_key_valid_until,
          secondary_key_valid_until: data.secondary_key_valid_until
        })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  cycleApiKeys(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/${this.props.id}/cycle_api_keys`, requestOptions)
    .then(response => {
      this.setState({resettingPassword: false})

      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        if (data.customer_api === true) {
          this.setState({
            primary_key: data.primary_key,
            secondary_key: data.secondary_key,
            primary_key_valid_until: data.primary_key_valid_until,
            secondary_key_valid_until: data.secondary_key_valid_until
          })
        }
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderPasswordConfirmationModal() {
    if (this.state.passwordResetConfirmationModal) {
      return (
        <div className="send-email-container">
          <div className="send-email-content">
            <label className="column">Request a password reset email to be sent to this address?</label>
            <div className="center"><strong>{this.state.saved_email}</strong></div>
            {this.renderUnsavedEmailChangeNotice()}
            <div className="grid-2-centered-columns">
              <div/>
              <button className="send-email-button" value="yes" onClick={this.requestPasswordReset} disabled={this.state.resettingPassword}>Request Email</button>
              {adminUser() && (<button className="send-email-button" value="link" onClick={this.requestPasswordResetLink} disabled={this.state.resettingPassword}>Request Link</button>)}
              <button className="send-email-button" value="no" onClick={this.cancelPasswordReset} disabled={this.state.resettingPassword}>Cancel</button>
              <div/>
            </div>

            {this.state.resetPasswordLink && (
              <div className="reset-password-link">
                <div className="center">Reset password link:</div>
                <input value={this.state.resetPasswordLink} readonly />
              </div>
            )}

            {this.state.passwordErrors &&
            <div>
              <div className="top-padding"/>
              {this.state.passwordErrors.errors.map((error, index) => (
                <div className="error" key={index}>{error}</div>
              ))}
            </div>
            }
          </div>
        </div>
      )
    }
  }

  renderUnsavedEmailChangeNotice() {
    if (this.state.email.trim().toLowerCase() !== this.state.saved_email.trim().toLowerCase()) {
      return (
        <div className="red-text">Note: if you wish to send the password reset email to a different address, you must save it first.</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Updated!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderFireLogLocationsSelection(assigned_locations, fire_log_location_ids) {
    if (assigned_locations.length > 0) {
      return (
        <table className="checkbox-table bottom-margin">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Send Emergency Fire Log Emails?</th>
            </tr>
            {assigned_locations.map((location) => (
              <tr key={location.id}>
                <td>
                  <Link to={`/location/${location.id}`}>{location.name}</Link>
                </td>
                <td className="center-cell">
                  <input className="column" type="checkbox" data-name={"fire_log" + location.name} name={location.id} data-key="fire_log_location_ids" data-use-integer="true" checked={fire_log_location_ids.includes(location.id)} onChange={this.handleMultiCheckbox} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }
    else {
      return (
        <NoticeBox type={"info"} text={"Only assigned Locations can be added as Fire Log Locations!"} />
      )
    }
  }

  render() {

    const { first_name, last_name, email, access_levels, external_sign_on_only, receive_critical_alerts, location_ids, fire_log_location_ids, customer_api, inactive, locations, loaded, locationsLoaded, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && locationsLoaded) {
      const baseLicense = localStorage.license === "base"

      return (
        <div>
          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <h3>Edit user</h3>

            <div className="row">
              <HelpText page={'user'} section={'firstName'} />

              <label className="column">First Name:</label>{ renderErrors(errors, 'first_name') }
              <input className="column" type="text" name="first_name" value={first_name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'lastName'} />

              <label className="column">Last Name:</label>{ renderErrors(errors, 'last_name') }
              <input className="column" type="text" name="last_name" value={last_name} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'email'} />

              <label className="column">Email Address:</label>{ renderErrors(errors, 'email') }
              <input className="column" type="email" name="email" value={email} onChange={this.handleChange} />
            </div>

            <div className="row">
              <HelpText page={'user'} section={'accessLevel'} />

              <label className="column">Access Level:</label>{ renderErrors(errors, 'access_level') }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Enable</th>
                  </tr>
                  {["admin", "manager", "reporting", "emergency"].map((access_level) => (
                    <tr key={access_level}>
                      <td>
                        {capitalize(access_level)}
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" name={access_level} data-key="access_levels" checked={access_levels.includes(access_level)} onChange={this.handleMultiCheckbox} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="row">
              <HelpText page={'user'} section={'external_sign_on_only'} />

              <input className="column" type="checkbox" name="external_sign_on_only" id="external_sign_on_only" checked={external_sign_on_only} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="external_sign_on_only">SSO required?</label>{ renderErrors(errors, 'external_sign_on_only') }
            </div>

            {baseLicense && (
              <div className="row">
                <HelpText page={'user'} section={'receive_critical_alerts'} />

                <input className="column" type="checkbox" name="receive_critical_alerts" id="receive_critical_alerts" checked={receive_critical_alerts} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="receive_critical_alerts">Receive critical alerts?</label>{ renderErrors(errors, 'receive_critical_alerts') }
              </div>
            )}

            <div className="row">
              <HelpText page={'user'} section={'locations'} />

              <label className="column">Locations:</label>{ renderErrors(errors, 'location') }
              <table className="checkbox-table">
              <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Allow Access?</th>
                  </tr>
                  {locations.map((location) => (
                    <tr key={location.id}>
                      <td>
                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" data-name={location.name} name={location.id} data-key="location_ids" data-use-integer="true" checked={location_ids.includes(location.id)} onChange={this.handleMultiCheckbox} />
                      </td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>

            {baseLicense && (
              <>
                <div className="row">
                  <HelpText page={'user'} section={'fire_log_locations'} />

                  <label className="column">Fire Log Locations:</label>{ renderErrors(errors, 'fire_log_location') }
                  {this.renderFireLogLocationsSelection(locations.filter(location => location_ids.includes(location.id)), fire_log_location_ids)}
                </div>

                <div className="row">
                  <HelpText page={'user'} section={'customer_api'} />

                  <input className="column" type="checkbox" name="customer_api" id="customer_api" checked={customer_api} onChange={this.toggleApiKeys} />
                  <label className="column checkbox-label" htmlFor="customer_api">Enable Customer API?</label>{ renderErrors(errors, 'customer_api') }
                </div>
              </>
            )}

            {customer_api && this.state.primary_key && <div className="row">
              <label className="column">Customer API:</label>{ renderErrors(errors, 'primary_key') } { renderErrors(errors, 'secondary_primary_key') }
              <div className="table big-table small-bottom-margin">
                <div className="tr heading">
                  <div className="th">Type</div>
                  <div className="th">Key</div>
                  <div className="th">Expiry</div>
                  <div className="th">Refresh</div>
                </div>
                <div className="tr">
                  <div className="td">Primary</div>
                  <div className="td"><input className="input-in-table" value={this.state.primary_key}/></div>
                  <div className="td">{this.state.primary_key_valid_until}</div>
                  <div className="td">
                    <div className="button button-in-table small" onClick={this.cycleApiKeys}>
                      Cycle keys
                    </div>
                  </div>
                </div>
                <div className="tr">
                  <div className="td">Secondary</div>
                  <div className="td"><input className="input-in-table" value={this.state.secondary_key}/></div>
                  <div className="td">{this.state.secondary_key_valid_until}</div>
                  <div className="td">
                    <div className="button button-in-table small" onClick={this.cycleApiKeys}>
                      Cycle keys
                    </div>
                  </div>
                </div>
              </div>

              <div className="right-container">
                <Link className="cool-button" to={{ pathname: "https://digital-reception.github.io/customer-api-docs/" }} target="_blank">
                  <h4>Customer API Documentation</h4>
                  <FontAwesomeIcon icon={faFilePdf} />
                </Link>
              </div>
            </div>}

            <div className="row">
              <HelpText page={'user'} section={'inactive'} />

              <input className="column" type="checkbox" name="inactive" id="inactive" checked={inactive} onChange={this.handleCheckbox} disabled={this.state.current_user} />
              <label className="column checkbox-label" htmlFor="inactive">Inactive?</label>{ renderErrors(errors, 'inactive') }
            </div>

            <div className="row">
              <HelpText page={'user'} section={'email_password_reset'} />
              <div className="green-button" id={this.props.id} onClick={this.confirmPasswordReset}>Email Password Reset</div>
              {this.renderPasswordConfirmationModal()}
            </div>

            <div className="top-padding"/>
            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </form>

          <Audits klass="User" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/users/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          saved_email: data.email,
          access_levels: data.access_level,
          external_sign_on_only: data.external_sign_on_only,
          receive_critical_alerts: data.receive_critical_alerts,
          location_ids: data.location_ids,
          fire_log_location_ids: data.fire_log_location_ids,
          customer_api: data.customer_api,
          inactive: data.inactive,
          current_user: data.current_user,
          loaded: true
        })

        if (data.customer_api === true) {
          this.setState({
            primary_key: data.primary_key,
            secondary_key: data.secondary_key,
            primary_key_valid_until: data.primary_key_valid_until,
            secondary_key_valid_until: data.secondary_key_valid_until
          })
        }
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/all_locations`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

  }
}

export default UpdateUser;
