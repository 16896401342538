import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import PopupBox from '../../components/shared/PopupBox';
import ManualCheckOutButton from '../../components/shared/ManualCheckOutButton';
import CreateCheckOutButton from '../../components/shared/CreateCheckOutButton';
import PrintButton from '../../components/shared/PrintButton';
import ListOfFireLogs from '../../components/fire_logs/ListOfFireLogs';

import { debounce } from '../../utilities/Generic.js'
import { spaceAndCapitalize } from '../../utilities/Forms.js'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class FireLog extends Component {
  constructor(props) {
    super(props);

    this.getFireLog = this.getFireLog.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNoteChange = this.handleNoteChange.bind(this);
    this.debouncedFireLogNoteSave = debounce(this.fireLogNoteSave.bind(this), 2000);
  }

  state = {
    visitors: [],
    residents: [],
    staff: [],
    contractors: [],
    industry_professionals: [],
    other: [],
    checked_ids: [],

    loaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  getFireLog() {
    const id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    let requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/${id}/fire_log`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          order: data.order,
          visitors: data.visitors,
          residents: data.residents,
          staff: data.staff,
          contractors: data.contractors,
          industry_professionals: data.industry_professionals,
          other: data.other,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  writeAccess() {
    const access = localStorage.getItem("access_levels");
    return access !== null && (access.includes("admin") || access.includes("emergency"))
  }

  handleNoteChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});

    this.debouncedFireLogNoteSave(value)
  }

  fireLogNoteSave(value){
    localStorage.setItem(`fire_log_notes`, value)
    localStorage.setItem("fire_log_latest_save", new Date().valueOf())
  }

  handleCheckbox(event) {
    let name = parseInt(event.target.name);
    let checked = this.state.checked_ids.includes(name);

    if (checked) {
      localStorage.setItem("fire_log_checked_in_ids", JSON.stringify(this.state.checked_ids.filter(element => element !== name)))
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.filter(element => element !== name)
      }))
    }
    else {
      localStorage.setItem("fire_log_checked_in_ids", JSON.stringify(this.state.checked_ids.concat(name)))
      this.setState(prevState => ({
        checked_ids: prevState.checked_ids.concat(name)
      }))
    }
    localStorage.setItem("fire_log_latest_save", new Date().valueOf())
  }

  handleSubmit(event) {
    // collect every person
    let people = {
      visitor: this.state.visitors,
      resident: this.state.residents,
      staff: this.state.staff,
      contractor: this.state.contractors,
      industry_professional: this.state.industry_professionals,
      other: this.state.other
    }

    let fire_log_people = []

    // manipulate person object to fire_log specification
    for (let type_of_person in people) {
      people[type_of_person].forEach((person) => {
        fire_log_people.push({
          id: person.id,
          name: `${person.first_name} ${person.last_name}`,
          check_in: person.check_in,
          checked: this.state.checked_ids.includes(person.id),
          type_of_person: type_of_person
        })
      })
    }

    const location_id = this.props.id


    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "fire_log": {
        "people": fire_log_people,
        "notes": this.state.notes,
        "completed": true
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/fire_logs?location_id=${location_id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ tabIndex: 1, updating: false, errors: "" })
        this.props.history.push(`${this.locationURL()}/fire-log/history`);
        localStorage.removeItem("fire_log_checked_in_ids")
        localStorage.removeItem("fire_log_latest_save")
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleTabChange(index) {
    if (index === 0) {
      this.props.history.push(`${this.locationURL()}/fire-log`);
    }
    else if (index === 1) {
      this.props.history.push(`${this.locationURL()}/fire-log/history`);
    }

    this.setState({ tabIndex: index });
  }

  locationURL() {
    // This ensures that the Users stays where they are on the site.
    if (this.props.location.pathname.includes("/location")) {
      return `/location/${this.props.id}`
    }
    else {
      return ""
    }
  }

  personTypeBlock(personType, people) {
    if (people.length !== 0) {
      return (
        <React.Fragment key={personType}>
          {personType === "Residents" && people.length > 0 &&
            <div className="information-box orange big-settings top-padding">
              <FontAwesomeIcon icon={faInfo} className="icon" />Residents on the Fire Log with their Arrival listed as "Resident" are Residents who have been automatically assumed to be on site as they are assigned only to this Location and have not yet Arrived or Left.
            </div>
          }
          <div className="person-type settings big-settings">
            <h3 className="widget-title">{personType}</h3>
            {people.map((person) => (
              <div className="inline-grid" key={person.id}>
                <input className="column" type="checkbox" id={`${personType}-${person.id}`} name={person.id} onChange={this.handleCheckbox} checked={this.state.checked_ids.includes(person.id)} />
                <label className="column checkbox-label" htmlFor={`${personType}-${person.id}`}>
                  <div className="person-details">
                    <div>{`${person.first_name || ""} ${person.last_name || ""}`}</div>
                    <div className="center">-</div>
                    <div>Arrived:</div>
                    <div className="center">-</div>
                    <div className="center">{person.check_in}</div>
                  </div>
                  <div className="person-details">
                    <div></div>
                    <div className="center">-</div>
                    <div>Phone Number:</div>
                    <div className="center">-</div>
                    <div className="center">{person.phone}</div>
                  </div>
                  {person.custom_attributes.map((custom_attribute, index) => (
                    <div className="person-details" key={index} >
                      <div></div>
                      <div className="center">-</div>
                      <div>{custom_attribute.name}:</div>
                      <div className="center">-</div>
                      <div className="center">{custom_attribute.value}</div>
                    </div>
                  ))}
                  {person.visited_people.length > 0 &&
                    person.visited_people.map((visited_person, index) => (
                      <div className="person-details" key={visited_person.id} >
                        <div></div>
                        <div className="center">{index === 0 ? "-" : ""}</div>
                        <div>{index === 0 ? "Visited People:" : ""}</div>
                        <div className="center">{index === 0 ? "-" : ""}</div>
                        <div className="center">{visited_person.name}</div>
                      </div>
                    ))
                  }
                  {this.renderManualCheckOutButton(person)}
                  {this.renderCreateCheckOutButton(person)}
                  <br/>
                </label>
              </div>
            ))}
          </div>
        </React.Fragment>
      )
    }
  }

  renderManualCheckOutButton(person) {
    if (person.check_in_event_id) {
      return <ManualCheckOutButton className="float-right" event_id={person.check_in_event_id} successCallback={this.getFireLog} />
    }
  }

  renderCreateCheckOutButton(person) {
    if (person.check_in_event_id === null) {
      return <CreateCheckOutButton className="float-right" person_id={person.id} location_id={this.props.id} successCallback={this.getFireLog} />
    }
  }

  renderErrors() {
    if (this.state.errors.length > 1) {
      this.state.errors.map((error, index) => (
        <div className="error" key={index}>{this.state.errors[index]}</div>
      ))
    }
  }

  renderSaveButton() {
    if (this.writeAccess()) {
      return(
        <div>
          <input className="section no-print" type="submit" value="Complete" onClick={this.handleSubmit} disabled={this.state.updating} />
        </div>
      )
    }
  }

  render() {
    const { order, visitors, residents, staff, contractors, industry_professionals, other, notes, tabIndex, loaded, id, updated, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`${this.locationURL()}/fire-log/history/${id}`}/>
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <Tabs selectedIndex={tabIndex} onSelect={index => this.handleTabChange(index)}>
          <PrintButton/>

          <TabList className="react-tabs__tab-list no-print">
            <Tab>Fire Log</Tab>
            <Tab>History</Tab>
          </TabList>

          <TabPanel>
            <div className="settings">
              <SetTitle title={`Fire Log | Locations`} />
              <PopupBox />
              <HelpText page={'fire_log'} section={'fire_log'} />
              <h3>Fire Log</h3>
              <div className="people-count">
                {order.map((type_of_person) => (
                  <div key={type_of_person}><span className="count-title">{spaceAndCapitalize(type_of_person)}</span>: {this.state[type_of_person].length}</div>
                ))}
              </div>

              <div className="people-count margin-top">
                <div><span className="count-title">Total</span>: {visitors.length + residents.length + staff.length + contractors.length + industry_professionals.length + other.length}</div>
              </div>

              {order.map((type_of_person) => (
                this.personTypeBlock(spaceAndCapitalize(type_of_person), this.state[type_of_person])
              ))}
              {this.renderErrors()}
              <div className="big-settings no-print">
                <label className="column">Notes:</label>
                <textarea aria-required="true" name="notes" value={notes} onChange={this.handleNoteChange} />
              </div>
              {this.renderSaveButton()}
            </div>
          </TabPanel>

          <TabPanel>
            <ListOfFireLogs location_id={this.props.id} fire_log_id={this.props.fire_log_id} />
          </TabPanel>
        </Tabs>
      );
    }
  }

  static getDerivedStateFromProps(props, state) {
    // deep linking tab selection
    const tabIndex = props.location.pathname.includes("/history") ? 1 : 0
    return {
      tabIndex: tabIndex,
      key: Math.random()
    }
  }

  componentDidMount() {
    // Grab in-progress fire log data from localStorage if it was saved recently
    if (localStorage.getItem("fire_log_latest_save") > new Date().setHours(new Date().getHours()-12)) {
      this.setState({
        checked_ids: JSON.parse(localStorage.getItem("fire_log_checked_in_ids")) || [],
        notes: localStorage.getItem("fire_log_notes") || ""
      })
    }
    // remove from localStorage if too old
    else {
      localStorage.removeItem("fire_log_latest_save")
      localStorage.removeItem("fire_log_checked_in_ids")
      localStorage.removeItem("fire_log_notes")
    }

    this.getFireLog()
  }
}

export default withRouter(FireLog);
