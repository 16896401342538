import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { debounce } from '../../utilities/Generic.js'
import Audits from '../../components/shared/Audits';

class UpdatePlannedEvent extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handlePartialChange = this.handlePartialChange.bind(this);
    this.debouncedHandlePersonSearch = debounce(this.handlePersonSearch.bind(this), 500);
    this.handleAddHostPerson = this.handleAddHostPerson.bind(this);
    this.handleAddArrivingPerson = this.handleAddArrivingPerson.bind(this);
    this.handleAddRelatedPerson = this.handleAddRelatedPerson.bind(this);
    this.handleAddRelatedHostPerson = this.handleAddRelatedHostPerson.bind(this);
    this.handleAddRelatedArrivingPerson = this.handleAddRelatedArrivingPerson.bind(this);
    this.handleRemovePerson = this.handleRemovePerson.bind(this);
    this.handleGetRelatedPeople = this.handleGetRelatedPeople.bind(this);
    this.handleSelectPerson = this.handleSelectPerson.bind(this);
    this.handleStopGettingRelatedPeople = this.handleStopGettingRelatedPeople.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    title: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",

    addPerson: null,
    partial: "",
    type_of_person: "",
    relationshipPersonID: "",
    arriving_person_ids: [],
    host_person_ids: [],

    selectedPeople: [],
    selectedPerson: "",
    selectedRelatedPerson: "",

    lastAddedPerson: null,

    people: [],
    relatedPeople: [],
    timezone_string: "",

    loaded: false,
    peopleLoaded: true,
    relatedPeopleLoaded: false,
    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  compileTime(date, time, timezone_string) {
    const jsDate = new Date(`${date} ${time}${timezone_string}`)
    return jsDate.toString()
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleTypeChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});

    this.handlePersonSearch(this.state.partial, value)
  }

  handlePartialChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({
      [name]: value,
      peopleLoaded: false
    });

    this.debouncedHandlePersonSearch(value, this.state.type_of_person)
  }

  handlePersonSearch(partial, type_of_person) {
    if (partial.trim() === "") {
      this.setState({ peopleLoaded: true });
      return
    }

    let params = `person[location]=${this.props.match.params.location_id}&person[partial]=${partial}`

    if (type_of_person !== "") {
      params += `&person[type][]=${type_of_person}`
    }

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/scoped_names_and_ids?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ people: data, peopleLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  handleAddHostPerson(event) {
    event.preventDefault();
    this.setState({ addPerson: "host"})
  }

  handleAddArrivingPerson(event) {
    event.preventDefault();
    this.setState({ addPerson: "arriving"})
  }

  handleAddRelatedPerson(type) {
    const selectedRelationship = this.state.relatedPeople.find(relationship => relationship.person_id === parseInt(this.state.selectedRelatedPerson))
    const selectedPerson = {
      "full_name": selectedRelationship.full_name,
      "id": selectedRelationship.person_id,
      "type_of_person": null
    }

    this.setState((prevState) => ({
      selectedPeople: [...prevState.selectedPeople, selectedPerson],
      [type + "_person_ids"]: [...prevState[type + "_person_ids"], parseInt(prevState.selectedRelatedPerson)],
      selectedRelatedPerson: ""
    }))
  }

  handleAddRelatedHostPerson(event) {
    event.preventDefault();
    this.handleAddRelatedPerson("host")
  }

  handleAddRelatedArrivingPerson(event) {
    event.preventDefault();
    this.handleAddRelatedPerson("arriving")
  }

  handleRemovePerson(event) {
    const name = event.target.attributes.name.value;
    const person_id = parseInt(event.target.id);

    this.setState((prevState) => ({
      [name]: prevState[name].filter((id) => id !== person_id),
      "selectedPeople": prevState["selectedPeople"].filter((person) => person.id !== person_id)
    }))
  }

  handleGetRelatedPeople(event) {
    const id = parseInt(event.target.id);

    this.setState({
      relatedPeople: [],
      relatedPeopleLoaded: false,
      relationshipPersonID: id
    })

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/people/${id}/relationships`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ relatedPeople: data, relatedPeopleLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  handleSelectPerson(event) {
    const person_id = event.target.value;
    const type = this.state.addPerson;

    const selectedPerson = this.state.people.find(person => person.id === parseInt(person_id))

    this.setState((prevState) => ({
      selectedPeople: [...prevState.selectedPeople, selectedPerson],
      [type + "_person_ids"]: [...prevState[type + "_person_ids"], parseInt(person_id)],
      lastAddedPerson: parseInt(person_id),
      selectedPerson: ""
    }))

    event.preventDefault();
  }

  handleStopGettingRelatedPeople(event) {
    this.setState({ relationshipPersonID: "" })

    event.preventDefault();
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "planned_event": {
        "title": this.state.title,
        "start_time": this.compileTime(this.state.start_date, this.state.start_time, this.state.timezone_string),
        "end_time": this.compileTime(this.state.end_date, this.state.end_time, this.state.timezone_string),
        "arriving_person_ids": this.state.arriving_person_ids,
        "host_person_ids": this.state.host_person_ids,
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/planned_events/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Planned Event updated successfully!");
        this.props.closePanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  highlightButton(type) {
    return this.state.addPerson === type ? " highlighted" : ""
  }

  lastAdded(person_id) {
    return this.state.lastAddedPerson === person_id ? " flash-highlight" : ""
  }

  renderAddPersonFields(loaded) {
    if (this.state.addPerson) {
      return (
        <>
          <select name="type_of_person" className="search-select" onChange={this.handleTypeChange} value={this.state.type_of_person}>
            <option value="">Filter by Person Type</option>
            <option value="visitor">Visitor</option>
            <option value="resident">Resident</option>
            <option value="staff">Staff</option>
            <option value="contractor">Contractor</option>
            <option value="industry professional">Industry Professional</option>
            <option value="other">Other</option>
          </select>
          <input name="partial" placeholder="Enter text to search" className="search-text" onChange={this.handlePartialChange} value={this.state.partial} />
          {loaded === false ? (
            <select name="selectedPerson" className="search-select" onChange={this.handleSelectPerson} value={this.state.selectedPerson}>
              <option value="">Loading...</option>
            </select>
          ) : (
            <select name="selectedPerson" className="search-text" onChange={this.handleSelectPerson} value={this.state.selectedPerson}>
              <option value="">Select a Person ({this.state.people.length})</option>
              {this.state.people.map((person) => (
                <option value={person.id} key={person.id} disabled={this.state.arriving_person_ids.includes(person.id) || this.state.host_person_ids.includes(person.id)}>{person.full_name}</option>
              ))}
            </select>
          )}
        </>
      )
    }
  }

  renderAddedPeople(type) {
    const otherType = type === "host" ? "Arriving" : "Host"

    const typeToIdsMapping = {
      "host": this.state.host_person_ids,
      "arriving": this.state.arriving_person_ids
    }

    const people = this.state.selectedPeople.filter(person => typeToIdsMapping[type].includes(person.id))

    // Adding people from the planned event #show data to fill in any gaps not covered by the people grab from this location
    let cached_people = [];
    if (this.state[type + "_person_ids"].length > people.length){
      const people_ids = people.map(person => person.id)
      cached_people = this.state["cached_"+type+"_people"].filter((person) => !people_ids.includes(person.id))
    }

    return (
      <div>
        {people.map((person) => (
          <>
            <div key={person.id} className={"tall-line" + this.lastAdded(person.id)}>
              <div>
                <span className={"bigger-font"}> {person.full_name} </span>
                {this.renderPersonButtons(type, person.id, otherType)}
              </div>
              {this.renderRelatedPeopleSelect(person.id, type, otherType)}
            </div>
            {this.renderEvent(type, person.id)}
          </>
        ))}

        {cached_people.map((person) => (
          <>
            <div key={person.id} className={"tall-line" + this.lastAdded(person.id)}>
              <div>
                <span className="bigger-font"> {person.full_name} </span>
                {this.renderPersonButtons(type, person.id, otherType)}
              </div>
              {this.renderRelatedPeopleSelect(person.id, type, otherType)}
            </div>
            {this.renderEvent(type, person.id)}
          </>
        ))}
      </div>
    )
  }

  renderEvent(type, person_id) {
    let event;
    if (type === "arriving"){
      event = this.state.arriving_people_events.find((event) => event.person_id === person_id)
    }

    if (event) {
      let check_in_text = event.event_check_in && event.event_check_in
      let check_out_text = event.event_check_out && event.event_check_out

      return (
        <div className="event-data">
          <div className="green-text">{check_in_text}</div>
          <div className="red-text">{check_out_text}</div>
        </div>
      )
    }
  }

  renderPersonButtons(type, person_id, otherType) {
    let event;
    if (type === "arriving") {
      event = this.state.arriving_people_events.find((event) => event.person_id === person_id)
    }

    if (event && event.event_id) {
      return (
        <div className="small button right" name={type+"_person_ids"} id={person_id} onClick={this.handleGetRelatedPeople}>Add Related People as {otherType}</div>
      )
    }
    else {
      return (
        <>
          <div className="small button right" name={type+"_person_ids"} id={person_id} onClick={this.handleGetRelatedPeople}>Add Related People as {otherType}</div>
          <div className="small button right" name={type+"_person_ids"} id={person_id} onClick={this.handleRemovePerson}>Remove</div>
        </>
      )
    }
  }

  renderCheckOut(event_check_out) {
    if (event_check_out) {
      return (
        " Check out: " + {event_check_out}
      )
    }
  }

  renderRelatedPeopleSelect(id, type, otherType) {
    if (this.state.relationshipPersonID === id) {
      const handleAddOtherTypePerson = type === "host" ? this.handleAddRelatedArrivingPerson : this.handleAddRelatedHostPerson;
      const selectText = this.state.relatedPeopleLoaded ? "Select" : "Loading...";

      return (
        <div>
          <select name="selectedRelatedPerson" className="column" onChange={this.handleChange} value={this.state.selectedRelatedPerson}>
            <option value="">{selectText}</option>
            {this.state.relatedPeople.map((person) => (
              <option value={person.person_id} key={person.person_id} disabled={this.state.arriving_person_ids.includes(person.person_id) || this.state.host_person_ids.includes(person.person_id)}>{person.full_name}</option>
            ))}
          </select>
          <HelpText page={'planned_events'} section={'related_people'} />
          <div className="small button right" onClick={this.handleStopGettingRelatedPeople}>Stop adding Related People</div>
          {this.state.selectedRelatedPerson && <div className="small button right" onClick={handleAddOtherTypePerson}>Add as {otherType} Person</div>}
        </div>
      )
    }
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input className="top-space" type="submit" value="Save" />
    }
    else {
      return <input className="top-space" type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { title, start_date, end_date, start_time, end_time, peopleLoaded, loaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={"Edit Planned Event | Planned Events"} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'planned_events'} section={'title'} />

              <label className="column">Title:</label>{ this.renderErrors('title') }
              <input className="column" type="text" name="title" value={title} onChange={this.handleChange} />

              {title &&
                <NoticeBox type="info" text="The Title will be used to refer to this Planned Event both in emails to People and when checking in on Devices, as well as here on the calendar."/>
              }
            </div>

            <div className="row">
              <HelpText page={'planned_events'} section={'start_time'} />

              <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              <div>
                <input className="column" type="date" name="start_date" value={start_date} onChange={this.handleChange} />
                <input className="column" type="time" name="start_time" value={start_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'planned_events'} section={'end_time'} />

              <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              <div>
                <input className="column" type="date" name="end_date" value={end_date} onChange={this.handleChange} />
                <input className="column" type="time" name="end_time" value={end_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'planned_events'} section={'people'} />

              <label className="column">People:</label>
            </div>
            <div className="row">
              <div className={"button add-person" + this.highlightButton("host")} onClick={this.handleAddHostPerson}>Add Person as Host</div>
              <div className={"button add-person" + this.highlightButton("arriving")} onClick={this.handleAddArrivingPerson}>Add Person as Visitor</div>
              {this.renderAddPersonFields(peopleLoaded)}
            </div>

            <div className="under-search">
              <HelpText className="title-help-text" page={'planned_events'} section={'host_people'} />
              <h3>Host People:</h3>
              {this.renderAddedPeople("host")} { this.renderErrors('host_person_ids') }

              <HelpText className="title-help-text" page={'planned_events'} section={'arriving_people'} />
              <h3>Arriving People:</h3>
              {this.renderAddedPeople("arriving")} { this.renderErrors('arriving_person_ids') }
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>

          <Audits klass="PlannedEvent" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/planned_events/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          title: data.title || "",
          start_time: data.start_time,
          end_time: data.end_time,
          start_date: data.start_date,
          end_date: data.end_date,
          arriving_person_ids: data.arriving_person_ids,
          host_person_ids: data.host_person_ids,
          timezone_string: data.timezone_string,
          arriving_people_events: data.arriving_people_events,
          cached_arriving_people: data.arriving_people,
          cached_host_people: data.host_people,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default withRouter(UpdatePlannedEvent);
