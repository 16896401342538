import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { spaceAndCapitalize } from '../../utilities/Forms.js'
import ModalContainerTrigger from '../../components/shared/ModalContainerTrigger'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const indexToRecordClassMap = {
  0: "Location"
}

class TidyUp extends Component {

  constructor(props) {
    super(props);

    this.handleRecordTabChange = this.handleRecordTabChange.bind(this);
    this.handleStatusTabChange = this.handleStatusTabChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.fetchDeletionImplications = this.fetchDeletionImplications.bind(this);
    this.requestDeletion = this.requestDeletion.bind(this);
    this.removeDeletion = this.removeDeletion.bind(this);
    this.handleDeletionConfirmation = this.handleDeletionConfirmation.bind(this);
    this.handleRemoveDeletionConfirmation = this.handleRemoveDeletionConfirmation.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  state = {
    staff: [],

    recordTabIndex: 0,
    statusTabIndex: 0,

    recordClass: indexToRecordClassMap[0],

    modalState: null,
    id: null,
    deletion_implications: {},

    loaded: false,
    loading: false,
    unauthorized: "",
    error: "",
    errors: ""
  };

  handleRecordTabChange(index) {
    const recordClass = indexToRecordClassMap[index];

    this.setState({
      recordTabIndex: index,
      recordClass: recordClass,
      deletion_implications: {}
    });

    this.fetchData(recordClass)
  }

  handleStatusTabChange(index) {
    this.setState({ statusTabIndex: index });
  }

  fetchData(recordClass) {
    this.setState({ loaded: false });

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `delete[record_class]=${recordClass}`

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/deletion_requests?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          records: data,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }

  fetchDeletionImplications(id) {
    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `delete[record_class]=${this.state.recordClass}&delete[record_id]=${id}`

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/deletion_requests/implications?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState(prevState => ({
          deletion_implications: {...prevState.deletion_implications, [id]: data}
        }))
      })
      .catch(error => this.setState({ error }))

  }

  requestDeletion(event) {
    const id = event.currentTarget.id;

    this.setState({
      modalState: "deletion_confirmation",
      id: id
    })

    if (!this.state.deletion_implications[id]) {
      this.fetchDeletionImplications(id)
    }
  }

  removeDeletion(event) {
    this.setState({
      modalState: "remove_deletion_confirmation",
      id: event.currentTarget.id
    })
  }

  handleDeletionConfirmation(event) {
    this.setState({ loading: true })

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let params = `delete[record_class]=${this.state.recordClass}&delete[record_id]=${this.state.id}`

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/deletion_requests?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          modalState: null,
          id: null,
          loading: false
        })
        this.fetchData(this.state.recordClass)

        sessionStorage.setItem("updateSuccess", "Deletion Request created succesfully!");
        this.props.updatePopupCallback(Math.random())
      })
      .catch(error => this.setState({ error, loading: false }))
  }

  handleRemoveDeletionConfirmation(event) {
    this.setState({ loading: true })

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const id = this.state.records.find(record => record.id.toString() === this.state.id).deletion_request_id

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/deletion_requests/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          modalState: null,
          id: null,
          loading: false
        })
        this.fetchData(this.state.recordClass)

        sessionStorage.setItem("updateSuccess", "Deletion Request removed succesfully!");
        this.props.updatePopupCallback(Math.random())
      })
      .catch(error => this.setState({ error, loading: false }))
  }

  closeModal() {
    this.setState({
      modalState: null,
      id: null
    })
  }

  renderError() {
    if (this.state.error.length > 0) {
      return (
        <div className="errors">{this.state.error}</div>
      )
    }
  }

  renderDeletionImplications(id) {
    const deletion_implications = this.state.deletion_implications[id];

    if (!deletion_implications) {
      return <div>Loading a list of dependant records which would be deleted alongside this record...</div>
    }

    return (
      <div>
        {Object.keys(deletion_implications).map(key => (
          <div key={key}>{spaceAndCapitalize(key)} - {deletion_implications[key]}</div>
        ))}
      </div>
    )
  }

  render() {
    const { id, records, deletion_implications, statusTabIndex, recordTabIndex, modalState, loaded, loading, unauthorized, error } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <div>
            <div className="top-text">
              <p>This is the Data Tidy Up page. Here you can see a list of records which can be deleted and see records already marked for deletion.</p>
              <p>You can choose to request deletion of records you no longer need. After a 2 week cool-off period, the record and all other records dependant on it will be deleted if that request has not been removed.</p>
            </div>
          </div>

          <br/>

          <Tabs selectedIndex={recordTabIndex} onSelect={value => this.handleRecordTabChange(value)}>
            <TabList>
              <Tab key={0}>Locations</Tab>
            </TabList>

            <TabPanel/>
          </Tabs>

          <Tabs selectedIndex={statusTabIndex} onSelect={value => this.handleStatusTabChange(value)}>
            <TabList>
              <Tab key={0}>Active</Tab>
              <Tab key={1}>Marked for deletion</Tab>
            </TabList>
            <TabPanel key={0}>
              <div className="table big-table">
                <div className="tr heading">
                  <div className="th">Name</div>
                  <div className="th">Request Deletion</div>

                </div>
                {records.filter(record => record.deletion_time === null).map((record) => (
                  <div key={record.id} id={record.id} className="tr">
                    <div className="td">{record.name}</div>
                    <div className="td center"><div className="small button" id={record.id} onClick={this.requestDeletion}>Request Deletion</div></div>
                  </div>
                ))}
              </div>
              {records.length === 0 && (
                <div className="long-tr">
                  <div className="long-td">
                    No records found.
                  </div>
                </div>
              )}
            </TabPanel>

            <TabPanel key={1}>
              <div className="table big-table">
                <div className="tr heading">
                  <div className="th">Name</div>
                  <div className="th">Deletion Time</div>
                  <div className="th">Requested By</div>
                  <div className="th">View Dependant Records</div>
                  <div className="th">Remove Deletion Request</div>
                </div>
                {records.filter(record => record.deletion_time !== null).map((record) => (
                  <div key={record.id} id={record.id} className="tr">
                    <div className="td">{record.name}</div>
                    <div className="td center">{record.deletion_time}</div>
                    <div className="td center"><Link className="visible-link" to={`/settings/users/${record.user_id}`}>{record.user_name}</Link></div>
                    <div className="td center">
                      {deletion_implications[record.id] ?
                        this.renderDeletionImplications(record.id) :
                        <div className="link" id={record.id} onClick={() => this.fetchDeletionImplications(record.id)}>View Dependants</div>
                      }
                    </div>
                    <div className="td center"><div className="small button red-button" id={record.id} onClick={this.removeDeletion}>Remove Deletion Request</div></div>
                  </div>
                ))}
              </div>
              {records.length === 0 && (
                <div className="long-tr">
                  <div className="long-td">
                    No records found.
                  </div>
                </div>
              )}
            </TabPanel>
          </Tabs>

          <ModalContainerTrigger showModal={modalState === "deletion_confirmation"} closeModal={this.closeModal} closeName="Cancel">
            <div className="modal-title">Deletion Confirmation Window</div>

            <div className="bottom-padding"><strong>Name:</strong> {records.find(record => record.id.toString() === id)?.name}</div>

            {this.renderDeletionImplications(id)}

            <div className="top-padding">Are you sure you wish to request deletion of all these records?</div>

            <div className="center-two-buttons">
              <button className="small button small-top-padding" value="ok" onClick={this.handleDeletionConfirmation} disabled={loading}>Yes, I wish to delete all of these records</button>
            </div>
          </ModalContainerTrigger>

          <ModalContainerTrigger showModal={modalState === "remove_deletion_confirmation"} closeModal={this.closeModal} closeName="Cancel">
            <div className="modal-title">Deletion Information for {records.find(record => record.id.toString() === id)?.name}</div>

            <div className="small-top-padding center">Are you sure you wish to remove this deletion request?</div>

            <div className="center-two-buttons">
              <button className="small button small-top-padding" value="ok" onClick={this.handleRemoveDeletionConfirmation} disabled={loading}>Yes, remove this deletion request and restore any dependant records</button>
            </div>
          </ModalContainerTrigger>

          {this.renderError()}
        </div>
      )
    }
  }

  componentDidMount() {
    this.fetchData(this.state.recordClass)
  }
}

export default TidyUp;
