import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { adminUser, spaceAndCapitalize, renderErrors } from '../../utilities/Forms.js'

const Audits = ({ klass, id }) => {
  const [audits, setAudits] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState("");
  const [unauthorized, setUnauthorized] = useState(false);

  const fetchAudits = async () => {
    setLoading(true)

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/audits?class=${klass}&id=${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 422) {
          errorsInResponse = true
          return response.json()
        }
        else if (response.status === 401) {
          setUnauthorized(true)
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        if (errorsInResponse) {
          setErrors(data)
        } else {
          setAudits(data)
        }

        setLoaded(true)
        setLoading(false)
      })
      .catch(error => setError(error))
  };

  const renderOperation = (operation) => {
    switch(operation) {
      case "create":
        return <div className="inline-block action-status completed-action not-clickable">Created</div>
      case "update":
        return <div className="inline-block action-status awaiting-sign-off-action not-clickable">Updated</div>
      case "destroy":
        return <div className="inline-block action-status blocked-action not-clickable">Deleted</div>
      default:
        return null
    }
  }

  const renderEditor = (editor) => {
    if (editor !== null) {
      return (
        <>
          by {editor}
        </>
      )
    }
  }

  const renderSource = (system_source) => {
    if (system_source !== null) {
      return (
        <>
          from {system_source}
        </>
      )
    }
  }

  const renderChanges = (changes) => {
    if (changes === null) {
      return
    }

    return (
      <ul>
        {Object.keys(changes).map(name => {
          const before = changes[name][0]
          const after = changes[name][1]

          return (
            <li>{spaceAndCapitalize(name)} changed from {spaceAndCapitalize(before)} to {spaceAndCapitalize(after)}</li>
          )
        })}
      </ul>
    )
  }

  // Only show Audits to Admins
  if (adminUser() === false) {
    return
  }

  if (unauthorized) {
    return <Redirect to="/login"/>
  }

  if (error) {
    return (
      <div className="settings big-settings">
        <div>{error.message}</div>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="settings big-settings">
        <p>Loading ...</p>
      </div>
    )
  }

  if (loaded === false) {
    return (
      <div className="settings big-settings">
        <button className="button small centered" onClick={fetchAudits}>Show Audits</button>
      </div>
    )
  }

  if (loaded === true) {
    return (
      <div className="settings big-settings">
        <h3>Last 10 Audits</h3>
        {renderErrors(errors, 'audits')}
        {audits?.map((audit) => (
          <div className="audit-box" key={audit.id}>
            <div className="row">{audit.created_at}</div>
            <div className="row">{renderOperation(audit.operation)} {renderEditor(audit.editor_name)} {renderSource(audit.system_source)}</div>
            {renderChanges(audit.attribute_changes)}
            <div>{audit.custom_message}</div>
          </div>
        ))}
        {audits.length === 0 && <div>No Audits within the last three months.</div>}
      </div>
    )
  }
}

export default Audits;
