import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'

class CreateFeedbackType extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    display: "",
    show_on: "Departure",
    anonymous: false,
    frequency: "always",
    ask_from: null,
    hidden: false,
    analysis_overview: true,

    updated: "",
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "feedback_type": {
        "name": this.state.name,
        "display": this.state.display,
        "show_on": this.state.show_on,
        "anonymous": this.state.anonymous,
        "hidden": this.state.hidden,
        "analysis_overview": this.state.analysis_overview,
      }
    })

    var requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ id: data.id, updated: true, updating: false, errors: "" })
      }
    })
    .catch(error => this.setState({ error, updating: false, loaded: true }))

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderUpdated(updated) {
    if (updated) {
      return (
        <div>
          Saved!
        </div>
      );
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" disabled={this.state.updating} />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const { name, display, show_on, anonymous, frequency, hidden, analysis_overview, id, updated, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (updated && id) {
      return <Redirect to={`/configuration/feedback/${id}`}/>
    }

    return (
      <div>
        <SetTitle title={"New Feedback Type | Feedback"} />

        <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
          <div className="segment">
            <div className="row">
                <HelpText page={'feedbackType'} section={'name'} />

              <label className="column">Feedback Type Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>

            <div className="row">
                <HelpText page={'feedbackType'} section={'display'} />

              <label className="column">Display Name:</label>{ this.renderErrors('display') }
              <input className="column" type="text" name="display" value={display} onChange={this.handleChange} />
            </div>

            <div className="row">
                <HelpText page={'feedbackType'} section={'showOn'} />

              <label className="column">Show On:</label>{ this.renderErrors('show_on') }
              <select name="show_on" onChange={this.handleChange} value={show_on}>
                <option value="Departure">Departure</option>
                <option value="Arrival">Arrival</option>
              </select>
            </div>

            <div className="row">
                <HelpText page={'feedbackType'} section={'anonymous'} />

              <input className="column" type="checkbox" name="anonymous" id="anonymous_checkbox" checked={anonymous} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="anonymous_checkbox">Anonymous?</label>{ this.renderErrors('anonymous') }
            </div>
          </div>

          {anonymous === false &&
            <div className="segment">
              <div className="row">
                <HelpText page={'feedbackType'} section={'frequency'} />

                <label className="column">Ask Frequency:</label>{ this.renderErrors('frequency') }

                <div className="ask-frequency">
                  <div className="question-type">
                    <input className="column" type="radio" name="frequency" id="always" value="always" checked={frequency === "always"} onChange={this.handleChange} />
                    <label htmlFor="always">Always</label>
                  </div>

                  <div className="question-type">
                    <input className="column" type="radio" name="frequency" id="weekly" value="weekly" checked={frequency === "weekly"} onChange={this.handleChange} />
                    <label htmlFor="weekly">Weekly</label>
                  </div>

                  <div className="question-type">
                    <input className="column" type="radio" name="frequency" id="monthly" value="monthly" checked={frequency === "monthly"} onChange={this.handleChange} />
                    <label htmlFor="monthly">Monthly</label>
                  </div>

                  <div className="question-type">
                    <input className="column" type="radio" name="frequency" id="once" value="once" checked={frequency === "once"} onChange={this.handleChange} />
                    <label htmlFor="once">Once</label>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="segment">
            <div className="row">
                <HelpText page={'feedbackType'} section={'hidden'} />

              <input className="column" type="checkbox" name="hidden" id="hidden_checkbox" checked={hidden} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="hidden_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
            </div>

            <div className="row">
              <HelpText page={'feedbackType'} section={'analysis'} />

              <input className="column" type="checkbox" name="analysis_overview" id="analysis_checkbox" checked={analysis_overview} onChange={this.handleCheckbox} />
              <label className="column checkbox-label" htmlFor="analysis_checkbox">Include in Analysis Graphs?</label>{ this.renderErrors('analysis_overview') }
            </div>

            <HelpText page={'feedbackType'} section={'createQuestions'} />

            { this.renderSaveButton() }
            { this.renderUpdated(updated) }
            { renderErrorWarning(errors) }
          </div>
        </form>
      </div>
    );
  }
}

export default CreateFeedbackType;
