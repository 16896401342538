import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';
import Audits from '../../components/shared/Audits';

import { adminUser, managerUser, hideElementForNonAdmins, renderErrorWarning } from '../../utilities/Forms.js'

class UpdateDevice extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleMultiCheckbox = this.handleMultiCheckbox.bind(this);
    this.resetDevice = this.resetDevice.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    location_id: "",
    qr_code_size: "default",
    profile_overflow: "scroll",
    profile_ids: [],

    locations: [],
    profiles: [],

    resetMessage: "",

    loaded: false,
    locationsLoaded: false,
    profilesLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleMultiCheckbox(event) {
    let name = parseInt(event.target.name);
    let value = event.target.checked;

    var profile_ids = this.state.profile_ids

    if (value === true) {
      if (!profile_ids.includes(name)) {
        profile_ids.push(name)
      }
    }
    else if (value === false) {
      if (profile_ids.includes(name)) {
        let index = profile_ids.indexOf(name)
        profile_ids.splice(index, 1)
      }
    }

    this.setState({profile_ids: profile_ids});
  }

  resetDevice(event) {
    const id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices/${id}/reset_device`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ resetMessage: "Sorry, something went wrong" })
      } else {
        this.setState({ resetMessage: "Device successfully reset" })
      }
    })
    .catch(error => this.setState({ error, loaded: true }))
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "device": {
        "name": this.state.name,
        "qr_code_size": this.state.qr_code_size,
        "profile_overflow": this.state.profile_overflow,
        "location_id": this.state.location_id,
        "profile_ids": this.state.profile_ids,
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Device updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }


  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {
    const { name, location_id, qr_code_size, profile_overflow, profile_ids, locations, profiles, resetMessage, loaded, locationsLoaded, profilesLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || locationsLoaded === false || profilesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded && locationsLoaded && profilesLoaded) {
      const disableNonProfileFields = (managerUser() === true && adminUser() === false)

      return (
        <div>
          <SetTitle title={`Edit Device | ${name} | Devices`} />

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            {disableNonProfileFields &&
              <NoticeBox type="info" text="As a Manager, you may only assign and unassign Profiles to this Device. Only Admins can change other fields." />
            }

            <div className="row">
              <HelpText page={'device'} section={'name'} />

              <label className="column">Device Name:</label>{ this.renderErrors('name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} disabled={disableNonProfileFields} />
            </div>

            <div className="row">
              <HelpText page={'device'} section={'location'} />

              <label className="column">Location:</label>{ this.renderErrors('location') }
              <select name="location_id" onChange={this.handleChange} value={location_id} disabled={disableNonProfileFields}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>

            <div className="row">
              <HelpText page={'device'} section={'qr_code_size'} />

              <label className="column">QR Code Size:</label>{ this.renderErrors('qr_code_size') }
              <div className="qr-code-options">
                <div className="qr-code-option">
                  <input className="column" type="radio" name="qr_code_size" id="default" value="default" checked={qr_code_size === "default"} onChange={this.handleChange} disabled={disableNonProfileFields} />
                  <label htmlFor="default">Default</label>
                  <div/>
                  <label className="sample-qr" htmlFor="default">Default QR size</label>
                </div>

                <div className="qr-code-option">
                  <input className="column" type="radio" name="qr_code_size" id="small" value="small" checked={qr_code_size === "small"} onChange={this.handleChange} disabled={disableNonProfileFields} />
                  <label htmlFor="small">Small</label>
                  <div/>
                  <label className="sample-qr-small" htmlFor="small">Reduced size</label>
                </div>
              </div>
            </div>

            <div className="row">
              <HelpText page={'device'} section={'profile_overflow'} />

              <label className="column">Profile overflow solution:</label>{ this.renderErrors('profile_overflow') }
              <div className="qr-code-options">
                <div className="qr-code-option">
                  <input className="column" type="radio" name="profile_overflow" id="scroll" value="scroll" checked={profile_overflow === "scroll"} onChange={this.handleChange} />
                  <label htmlFor="scroll">Profiles always on one row</label>
                </div>

                <div className="qr-code-option">
                  <input className="column" type="radio" name="profile_overflow" id="rows" value="rows" checked={profile_overflow === "rows"} onChange={this.handleChange} />
                  <label htmlFor="rows">Profiles appear on multiple rows</label>
                </div>
              </div>
            </div>

            <div className="row">
              <HelpText page={'device'} section={'profiles'} />

              <label className="column">Profiles:</label>{ this.renderErrors('profile') }
              <div className="scrollable-table">
                <table className="checkbox-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Use</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profiles.map((profile) => (
                      <tr key={profile.id}>
                        <td>
                          <Link to={`/configuration/profiles/${profile.id}`}>{profile.name}</Link>
                        </td>
                        <td>
                          {profile.display_name}
                        </td>
                        <td className="center-cell">
                          <input className="column" type="checkbox" data-name={profile.name} name={profile.id} checked={profile_ids.includes(profile.id)} onChange={this.handleMultiCheckbox} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </form>

          <div className={`reset-device big-settings ${hideElementForNonAdmins()}`}>
            <HelpText page={'device'} section={'resetDevice'} />

            <div className="button new-button red-button" onClick={this.resetDevice}>Reset Device</div>
            <p>{resetMessage}</p>
          </div>

          <Audits klass="Device" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/devices/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          name: data.name,
          location_id: data.location_id,
          qr_code_size: data.qr_code_size,
          profile_overflow: data.profile_overflow,
          profile_ids: data.profile_ids,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/profiles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ profiles: data, profilesLoaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))

  }
}

export default UpdateDevice;
