import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HelpText from '../../components/help/HelpText';

class EventsPanel extends Component {
  constructor(props) {
    super(props);

    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.toggleFeedback = this.toggleFeedback.bind(this);
  }


  state = {
    events: [],
    feedbackIDs: [],
    omitted: 0,
    page: 1,

    loaded: false,
    error: null,
    unauthorized: null,
    invalid_params: false
  };

  handlePreviousPage() {
   this.setState(prevState => ({
     page: parseInt(prevState.page) - 1,
   }))
  }

  handleNextPage() {
   this.setState(prevState => ({
     page: parseInt(prevState.page) + 1
   }))
  }

  toggleFeedback(event) {
    let id = event.currentTarget.id;

    if (this.state.feedbackIDs.includes(id)) {
      this.setState((prevState) => ({
        feedbackIDs: prevState.feedbackIDs.filter(item => item !== id)
      }))
    }
    else  {
      this.setState((prevState) => ({
        feedbackIDs: prevState.feedbackIDs.concat([id])
      }))
    }
  }

  colourScore(score) {
    if (score === null) {
      return "grey-text"
    }
    if (score < 0) {
      return "red-text"
    }
    if (score > 0) {
      return "green-text"
    }
    else {
      return ""
    }
  }

  renderCheckOutWithContext(event) {
    // no context required if check out is device only
    if (event.reported_check_out_present === false){
      return event.check_out
    }

    let title = "";

    if (event.device_check_out_present === false) {
      title = "This Left time was not sourced from a Device. It could have been caused by the Auto Checkout process or a manual Left time using this app."
    }
    else {
      title = "Both a Device Left time and a Reported Left time have been applied to this Event, but only the Device Left time is being displayed currently."
    }
    return <span title={title}>{event.check_out}*</span>
  }

  renderFeedback(event) {
    if (this.state.feedbackIDs.includes(event.id)) {
      return (
        <div className="table big-table" data-testid={`table for ${event.id}`}>
          <div className="tr heading" key={event.id} id={event.id} onClick={this.toggleFeedback}>
            <div className="th">Question</div>
            <div className="th">Answer</div>
            <div className="th">Included in Analysis?<HelpText className="th-helptext" page={'analysis'} section={'included_in_analysis'} /></div>
            <div className="th">Action Needed?</div>
          </div>
          {event.feedback_answers.map((feedback_answer) => (
          <div className="tr" id={event.id + feedback_answer.id} key={event.id + feedback_answer.id}>
            <Link to={`/configuration/feedback/${feedback_answer.type_id}/question/${feedback_answer.question_id}`} className="td visible-link">{feedback_answer.question}</Link>
            <div className={`td ${this.colourScore(feedback_answer.score)}`}>{feedback_answer.answer}</div>
            <Link to={`/configuration/feedback/${feedback_answer.type_id}`} className="td visible-link center">{feedback_answer.included_in_analysis ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</Link>
            <div className={`td center`}>{feedback_answer.action_needed ? <FontAwesomeIcon icon={faCheckCircle} /> : <FontAwesomeIcon icon={faTimes} />}</div>
          </div>
          ))}
        </div>
      )
    }
  }

  renderShowFeedbackButton(event) {
    if (event.feedback_answers.length === 0) {
      return
    }

    let value = "Show Feedback";

    if (this.state.feedbackIDs.includes(event.id)) {
      value = "Hide Feedback";
    }

    return (
      <div className="event-panel small button">{value}</div>
    )
  }

  renderPageButtons(page) {
    const event_ids = this.props.event_ids || []

    if (event_ids.length < 100) {
      return ""
    }
    else {
      return (
        <div className="page-button-container">
          <button className="button new-button" onClick={this.handleNextPage} disabled={event_ids.length < (100 * parseInt(page) + 1)}>Next Page</button>
          <button className="button new-button" onClick={this.handlePreviousPage} disabled={parseInt(page) < 2}>Previous Page</button>
        </div>
      )
    }
  }

  render() {
    const { events, loaded, omitted, page, unauthorized, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return (
        <div>
          {this.renderPageButtons(page)}
          Loading
          <span className="blink1">.</span>
          <span className="blink2">.</span>
          <span className="blink3">.</span>
        </div>
      )
    }

    if (loaded) {
      return (
        <div>
          {this.renderPageButtons(page)}

          {events.map((event) => (
            <div key={event.id}>
              <div className="event-row top-padding" id={event.id} onClick={event.feedback_answers.length > 0 ? this.toggleFeedback : undefined}>
                <div><strong>Person</strong>: {event.person_name}</div>
                <div><strong>Arrived</strong>: {event.check_in}</div>
                <div><strong>Left</strong>: {this.renderCheckOutWithContext(event)}</div>
                <div><strong>Type</strong>: {event.type_of_person}</div>
                <div><strong>Location</strong>: {event.location}</div>
                <div><strong>Action Status</strong>: {event.action_status}</div>
                <div></div>
                <div></div>
                <div>{this.renderShowFeedbackButton(event)}</div>
              </div>
              {this.renderFeedback(event)}
            </div>
          ))}
          {omitted > 0 &&
            <div className="top-padding"> {omitted} event{omitted > 1 && "s"} {omitted > 1 ? "have" : "has"} been omitted for anonymity purposes.</div>
          }
        </div>
      )
    }
  }

  buildParams() {
    // API can't handle too many event_ids at once.
    // Doing them in batches of 100.
    const { page } = this.state;
    const startIndex = (page - 1) * 100;
    const endIndex = startIndex + 100;
    const events = this.props.event_ids?.slice(startIndex, endIndex) || [];

    let params = ""
    for (let event_id of events) {
      if (params !== "") {
        params+= "&"
      }

      params+= `events[event_ids][]=${event_id}`
    }

    return params
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && this.state.page !== prevState.page) {
      // Display loading... message
      this.setState({loaded: false})

      const headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      const requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      const params = this.buildParams()

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({
              error: JSON.stringify(response.body),
              unauthorized: true
            })
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ events: data, loaded: true })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
  }

  componentDidMount() {
    const headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    const params = this.buildParams()

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events?${params}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({
            error: JSON.stringify(response.body),
            unauthorized: true
          })
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ events: data, omitted: this.props.event_ids.filter(event_id => event_id === null).length, loaded: true })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default EventsPanel;
