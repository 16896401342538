import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import NoticeBox from '../../components/shared/NoticeBox';
import HelpText from '../../components/help/HelpText';

import { adminUser, renderErrorWarning } from '../../utilities/Forms.js'
import Audits from '../../components/shared/Audits';
import ListOfFeedbackQuestions from '../../components/feedback_types/ListOfFeedbackQuestions';

class UpdateFeedbackType extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetAskFrom = this.resetAskFrom.bind(this);
  }

  state = {
    name: "",
    display: "",
    show_on: "Departure",
    static_association: false,
    hidden: false,
    anonymous: false,
    analysis_overview: false,

    loaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    let name = event.target.name;
    let value = event.target.checked;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    var id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "feedback_type": {
        "name": this.state.name,
        "display": this.state.display,
        "show_on": this.state.show_on,
        "anonymous": this.state.anonymous,
        "frequency": this.state.frequency,
        "hidden": this.state.hidden,
        "analysis_overview": this.state.analysis_overview,
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Feedback Type updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  resetAskFrom(event) {
    var id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/${id}/reset_ask_from`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ ask_from: data.ask_from })
        sessionStorage.setItem("updateSuccess", "Ask from date reset successfully! Everyone will now be asked to answer this Feedback Type once again.");
        this.props.updatePopupCallback()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  renderAutumnaMessage() {
    if (this.state.static_association) {
      return (
        <NoticeBox type={"info"} text={"This feedback is linked directly to an externally curated Feedback Type which is designed to be universal across all systems.<br /><br />For this reason, some functionality is reduced, such as the ability to edit titles or questions."} />
      )
    }
  }

  render() {
    const { name, display, show_on, static_association, anonymous, frequency, ask_from, hidden, analysis_overview, loaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/> 
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div>
          <SetTitle title={`Edit Feedback Type | ${name} | Feedback`} />

          <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
            {this.renderAutumnaMessage()}

            <div className="segment">
              <div className="row">
                <HelpText page={'feedbackType'} section={'name'} />

                <label className="column">Feedback Type Name:</label>{ this.renderErrors('name') }
                <input className="column" type="text" name="name" value={name} onChange={this.handleChange} disabled={static_association} />
              </div>

              <div className="row">
                <HelpText page={'feedbackType'} section={'display'} />

                <label className="column">Display Name:</label>{ this.renderErrors('display') }
                <input className="column" type="text" name="display" value={display} onChange={this.handleChange} disabled={static_association} />
              </div>

              <div className="row">
                <HelpText page={'feedbackType'} section={'showOn'} />

                <label className="column">Show On:</label>{ this.renderErrors('show_on') }
                <select name="show_on" onChange={this.handleChange} value={show_on} disabled={static_association}>
                  <option value="Departure">Departure</option>
                  <option value="Arrival">Arrival</option>
                </select>
              </div>

              <div className="row">
                <HelpText page={'feedbackType'} section={'anonymous'} />

                <input className="column" type="checkbox" name="anonymous" id="anonymous_checkbox" checked={anonymous} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="anonymous_checkbox">Anonymous?</label>{ this.renderErrors('anonymous') }
              </div>
            </div>

            {anonymous === false &&
              <div className="segment">
                <div className="row">
                  <HelpText page={'feedbackType'} section={'frequency'} />

                  <label className="column">Ask Frequency:</label>{ this.renderErrors('frequency') }

                  <div className="ask-frequency">
                    <div className="question-type">
                      <input className="column" type="radio" name="frequency" id="always" value="always" checked={frequency === "always"} onChange={this.handleChange} disabled={static_association} />
                      <label htmlFor="always">Always</label>
                    </div>

                    <div className="question-type">
                      <input className="column" type="radio" name="frequency" id="weekly" value="weekly" checked={frequency === "weekly"} onChange={this.handleChange} disabled={static_association} />
                      <label htmlFor="weekly">Weekly</label>
                    </div>

                    <div className="question-type">
                      <input className="column" type="radio" name="frequency" id="monthly" value="monthly" checked={frequency === "monthly"} onChange={this.handleChange} disabled={static_association} />
                      <label htmlFor="monthly">Monthly</label>
                    </div>

                    <div className="question-type">
                      <input className="column" type="radio" name="frequency" id="once" value="once" checked={frequency === "once"} onChange={this.handleChange} disabled={static_association} />
                      <label htmlFor="once">Once</label>
                    </div>
                  </div>

                  {frequency === "once" && ask_from &&
                    <>
                      <NoticeBox type={"info"} text={`Each person will be asked to give Feedback from this Type once after ${ask_from}.`} />
                      <HelpText page={'feedbackType'} section={'reset_ask_from'} />
                      {adminUser() &&
                        <button className="button" onClick={this.resetAskFrom}>Ask once from now</button>
                      }
                    </>
                  }
                </div>
              </div>
            }

            <div className="segment">
              <div className="row">
                <HelpText page={'feedbackType'} section={'hidden'} />

                <input className="column" type="checkbox" name="hidden" id="hidden_checkbox" checked={hidden} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="hidden_checkbox">Hidden?</label>{ this.renderErrors('hidden') }
              </div>

              <div className="row">
                <HelpText page={'feedbackType'} section={'analysis'} />

                <input className="column" type="checkbox" name="analysis_overview" id="analysis_checkbox" checked={analysis_overview} onChange={this.handleCheckbox} />
                <label className="column checkbox-label" htmlFor="analysis_checkbox">Include in Analysis Graphs?</label>{ this.renderErrors('analysis_overview') }
              </div>

              { this.renderSaveButton() }
              { renderErrorWarning(errors) }
            </div>
          </form>

          <ListOfFeedbackQuestions feedback_id={this.props.id} question_id={this.props.question_id} disabled={static_association} />

          <Audits klass="FeedbackType" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/feedback_types/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          name: data.name,
          display: data.display,
          show_on: data.show_on,
          static_association: data.static_association,
          anonymous: data.anonymous,
          frequency: data.frequency,
          ask_from: data.ask_from,
          hidden: data.hidden,
          analysis_overview: data.analysis_overview,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))
  }
}

export default UpdateFeedbackType;
