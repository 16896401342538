import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import UpdateAction from '../../components/actions/UpdateAction';

import { capitalizeAllWords } from '../../utilities/Forms.js'
import { arrayParamBuilder } from '../../utilities/Generic.js'

import { CSVLink } from "react-csv";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class ActionsTaken extends Component {
  constructor(props) {
    super(props);

    this.handleUpdatePanel = this.handleUpdatePanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  closePanel() {
    this.setState({
      editPanelToggle: false,
      actionId: null
    });

    this.props.history.push(this.state.previousURL);
    this.componentDidMount();
  }

  formatDate(date, minus) {
    date.setDate(date.getDate() - minus);
    let day = ("0" + date.getDate()).slice(-2)
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let year = date.getFullYear()

    return `${year}-${month}-${day}`
  }

  emptyActions(actions) {
    if (actions.length === 0) {
      return (
        <div className="long-tr">
          <div className="long-td">
            { this.emptyActionsText() }
          </div>
        </div>
      )
    }
  }

  emptyActionsText() {
    let text = "No actions to show"

    let content = []
    this.props.question !== "" && content.push(" Question")
    this.props.selected_location !== "" && content.push(" Location")
    this.props.type_of_person !== "" && content.push(" Type")

    if (content.length > 0) {
      text +=  " for this"
      for (let index = content.length - 1; index > 0; index--) {
        content.splice(index, 0, " and")
      }

      for (const index of content) {
        text += index
      }
    }

   if (this.props.action_statuses) {
    text += " with this action status"
   }
   text += " in this time range."

   return text
  }

  downloadCSV(actions) {
    let filename = `actions_taken-export-${this.formatDate(new Date(), 0)}.csv`

    let headers = [
      { label: "Outstanding Action ID", key: "outstanding_action_id" },
      { label: "Action Taken At", key: "created_at" },
      { label: "Action Taken By", key: "user" },
      { label: "Action Type", key: "action_type" },
      { label: "Action Outcome", key: "action_outcome" },
      { label: "Action Status", key: "action_status" },
      { label: "Name", key: "person" },
      { label: "Person Type", key: "type_of_person" },
      { label: "Date of Problem", key: "date_of_problem" },
      { label: "Problem Type", key: "problem_type" },
      { label: "Location", key: "location" }
    ];

    if (actions.length === 0) {
      actions = [{[headers[0].key]: this.emptyActionsText()}]
    }

    return (
      <CSVLink data={actions} headers={headers} filename={filename} className="edit-button download-csv">
        <div>
          <FontAwesomeIcon icon={faFileDownload} />
          <h4>Download CSV</h4>
        </div>
      </CSVLink>
    )
  }

  handleUpdatePanel(event) {
    let id = event.currentTarget.id;

    this.setState({
      actionId: id,
      editPanelToggle: true,
      previousURL: `${this.props.location.pathname}${this.props.location.search}`
    });
    this.props.history.push(`/exports/actions-taken/${id}`)
  }

  colourActionsByStatus(action) {
    switch(action.action_status) {
      case "Open":
        return "open-action"
      case "Started":
        return "started-action"
      case "Awaiting sign-off":
        return "awaiting-sign-off-action"
      case "Blocked":
        return "blocked-action"
      case "Completed":
        return "completed-action"
      case "Abandoned":
        return "abandoned-action"
      default:
        return ""
    }
  }

  dividerClass(action, index) {
    if (!this.props.grouped) {
      return ""
    }

    let customClass = ""

    if (index !== this.state.actions.length - 1 && action.outstanding_action_id !== this.state.actions[index + 1].outstanding_action_id) {
      customClass = " bottom-divider"
    }

    if (index !== 0 && action.outstanding_action_id !== this.state.actions[index - 1].outstanding_action_id) {
      customClass += " top-divider"
    }


    return customClass
  }

  render() {
    const { actions, loaded, error, unauthorized } = this.state;

    if (localStorage.license === "entry") {
      return <Redirect to="/upgrade" />
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false) {
      return <p>Loading ...</p>;
    }

    if (loaded) {
      return (
        <div className="relative">
          {this.downloadCSV(actions)}

          <div className="table big-table top-padding" data-testid="table">
            <div className="tr heading">
              <div className="th">Location</div>
              <div className="th">Problem Type</div>
              <div className="th">Date of Problem</div>
              <div className="th">Person Type</div>
              <div className="th right-divider">Name</div>
              <div className="th left-divider">Action Status</div>
              <div className="th">Action Taken At</div>
              <div className="th">Action Taken By</div>
              <div className="th">Action Type</div>
              <div className="th">Action Outcome</div>
            </div>

            {actions.map((action, index) =>
              <Link to={`/exports/actions-taken/${action.outstanding_action_id}`} onClick={this.handleUpdatePanel} className={`tr event ${this.colourActionsByStatus(action)}${this.dividerClass(action, index)}`} key={action.id} id={action.outstanding_action_id}>
                <div className="td">{action.location}</div>
                <div className="td">{capitalizeAllWords(action.problem_type.replace("cool ", ""))}</div>
                <div className="td">{action.date_of_problem}</div>
                <div className="td">{action.type_of_person}</div>
                <div className="td right-divider">{action.person}</div>
                <div className="td center left-divider">{action.action_status}</div>
                <div className="td">{action.created_at}</div>
                <div className="td">{action.user}</div>
                <div className="td">{capitalizeAllWords(action.action_type)}</div>
                <div className="td">{action.action_outcome?.slice(0, 200)}</div>
              </Link>
            )}
            </div>

          {this.emptyActions(actions)}

          <SlidingPane isOpen={this.state.editPanelToggle} title="Update Actions" width="60%"
            onRequestClose={this.closePanel}>
            <UpdateAction outstanding_action_id={this.state.actionId} closePanel={this.closePanel} />
          </SlidingPane>
        </div>
      );
    }
  }

  state = {
    actions: [],
    loaded: false,
    error: null,
    unauthorized: null,

    actionId: null,
    editPanelToggle: false,
    previousURL: '/exports/actions-taken'
  };

  static getDerivedStateFromProps(props, state) {
    if (state.editPanelToggle && props.id === undefined) {
      return {
        editPanelToggle: false
      }
    }
    else if (state.editPanelToggle && props.id !== state.actionId) {
      return {
        actionId: props.id,
        key: Math.random()
      }
    }
    // handle back/forward buttons
    if (props.history.action === "POP" && props.history.location.pathname.endsWith("/actions-taken")) {
      return {
        editPanelToggle: false,
        actionId: null
      }
    }
    else if (props.history.action === "POP" && props.id !== undefined) {
      return {
        editPanelToggle: true,
        actionId: props.id
      }
    }
    else {
      return null
    }
  }

  componentDidMount() {
    // deep linking
    if (this.props.id !== undefined && this.state.editPanelToggle === false) {
      this.setState({
        editId: this.props.id,
        editPanelToggle: true
      });
    }

    if (this.props.date_from !== "" && this.props.date_to !== "") {
      var headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");
      let params = `actions[date_from]=${this.props.date_from}&actions[date_to]=${this.props.date_to}&actions[grouped]=${this.props.grouped === true ? true : ""}&actions[question]=${this.props.question}&actions[location]=${this.props.selected_location}&actions[person]=${this.props.type_of_person}&actions[search]=${this.props.search}&actions[user_search]=${this.props.user_search}${arrayParamBuilder("actions", "action_status", this.props.action_statuses)}`

      var requestOptions = {
        method: 'GET',
        headers: headers,
        credentials: 'include',
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/exports/actions?${params}`, requestOptions)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          else if (response.status === 401) {
            this.setState({error: JSON.stringify(response.body)})
            this.setState({unauthorized: true})
          }
          else {
            throw new Error('Something went wrong ...');
          }
        })
        .then(data => {
          this.setState({ actions: data, loaded: true })
        })
        .catch(error => this.setState({ error, loaded: true }))
    }
  }
}

export default withRouter(ActionsTaken);
