import React, { Component } from 'react';

import { adminUser, managerUser } from '../../utilities/Forms.js'

class ManualCheckOutButton extends Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    showModal: false,
    date: this.initialDate(),
    time: "",

    errors: ""
  }

  initialDate() {
    const date = this.props.check_in_date || new Date();
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
  }

  openModal(event) {
    this.setState({showModal: true})

    event.preventDefault();
  }

  closeModal(event) {
    this.setState({showModal: false})

    event.preventDefault();
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    let id = this.props.event_id;

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "event": {
        "reported_check_out": `${this.state.date} ${this.state.time}`
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/events/${id}/manual_check_out`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data.errors })
      } else {
        this.setState({
          errors: "",
          showModal: false
        })
        sessionStorage.setItem("updateSuccess", "Event updated successfully!");

        // A callback fired on success can be used to update data on the page this request took place
        // or it could be used to simply force the PopupBox to show the success message
        if (this.props.successCallback) {
          this.props.successCallback(data);
        }
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderModal() {
    if (this.state.showModal) {
      return (
        <div className="modal-container">
          <div className="modal-content manual-check-out">
            <div className="modal-title">Set Manual Time Left</div>
            {this.renderErrors()}
            <div>Please enter the date and time this Person Left for this Event:</div>
            <input type="date" name="date" value={this.state.date} onChange={this.handleChange} />
            <input type="time" name="time" value={this.state.time} onChange={this.handleChange} />

              <div className="center-two-buttons">
                <button className="small button" onClick={this.handleSubmit}>Submit Time Left</button>
                <button className="small button" onClick={this.closeModal}>Cancel</button>
              </div>
          </div>
        </div>
      )
    }
  }

  renderErrors() {
    if (this.state.errors) {
      let array = []
      for (let key in this.state.errors) {
          array = array.concat(this.state.errors[key])
      }
      return(
        array.map((item, index) => (
          <label key={index} className="column red-text">{item}</label>
        ))
      )
    }
  }

  render() {
    const event_id = this.props.event_id;
    const className = this.props.className ? " " + this.props.className : "";

    if (event_id && (adminUser() || managerUser())) {
      return (
        <>
          <div className={"small button no-print" + className} onClick={this.openModal}>Set Manual Time Left</div>
          {this.renderModal()}
        </>
      );
    }
  }
}

export default ManualCheckOutButton;
