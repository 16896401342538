import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';

import { adminUser, managerUser, renderErrorWarning, renderErrors } from '../../utilities/Forms.js'
import Audits from '../../components/shared/Audits';

class UpdateCustomAttribute extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleRoleMultiCheckbox = this.handleRoleMultiCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    name: "",
    location_id: "",
    role_ids: [],

    locations: [],
    roles: [],

    loaded: false,
    rolesLoaded: false,
    locationsLoaded: false,
    unauthorized: "",
    errors: "",
    error: ""
  };

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  handleCheckbox(event) {
    const name = event.target.name;
    const value = event.target.checked;

    this.setState({[name]: value});
  }

  handleRoleMultiCheckbox(event) {
    const name = parseInt(event.target.name);
    const value = event.target.checked;

    let role_ids = this.state.role_ids

    if (value === true) {
      if (!role_ids.includes(name)) {
        role_ids.push(name)
      }
    }
    else if (value === false) {
      if (role_ids.includes(name)) {
        let index = role_ids.indexOf(name)
        role_ids.splice(index, 1)
      }
    }

    this.setState({role_ids: role_ids});
  }

  handleSubmit(event) {
    let id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    const json = JSON.stringify({
      "rota_type": {
        "name": this.state.name,
        "location_id": this.state.location_id,
        "role_ids": this.state.role_ids
      }
    })

    const requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    let errorsInResponse = false

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ errors: data })
      } else {
        this.setState({ errors: "" })
        sessionStorage.setItem("updateSuccess", "Rota Type updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ error, loaded: true }))

    event.preventDefault();
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <input type="submit" value="Save" />
    }
    else {
      return <input type="submit" value="Sorry, you don't have permission to save changes" disabled="disabled" />
    }
  }

  render() {

    const { name, location_id, role_ids, roles, locations, loaded, rolesLoaded, locationsLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (loaded === false || rolesLoaded === false || locationsLoaded === false) {
      return <p>Loading ...</p>;
    }

    return (
      <div>
        <SetTitle title={`Edit Rota Type | ${name} | Staff Fulfilment`} />

        <form className="settings big-settings segment-settings" onSubmit={this.handleSubmit}>
          <div className="segment">
            <div className="row">
              <HelpText page={'rota_type'} section={'name'} />

              <label className="column">Name:</label>{ renderErrors(errors, 'name') }
              <input className="column" type="text" name="name" value={name} onChange={this.handleChange} />
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'rota_type'} section={'location'} />

              <label className="column">Location:</label>{ renderErrors(errors, 'location') }
              <select name="location_id" onChange={this.handleChange} value={location_id}>
                <option value="">Select</option>
                {locations.map((location) => (
                  <option value={location.id} key={location.id}>{location.name}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="segment">
            <div className="row">
              <HelpText page={'rota_type'} section={'roles'} />

              <label className="column">Roles:</label>{ renderErrors(errors, 'role') }
              <table className="checkbox-table">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Use</th>
                  </tr>
                  {roles.map((role) => (
                    <tr key={role.id}>
                      <td>
                        <Link to={`/configuration/roles/${role.id}`}>{role.name}</Link>
                      </td>
                      <td className="center-cell">
                        <input className="column" type="checkbox" data-name={role.name} name={role.id} checked={role_ids.includes(role.id)} onChange={this.handleRoleMultiCheckbox} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="segment">
            { this.renderSaveButton() }
            { renderErrorWarning(errors) }
          </div>
        </form>

        <Audits klass="RotaType" id={this.props.id} />
      </div>
    );
  }

  componentDidMount() {
    const id = this.props.id;

    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/rota_types/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          name: data.name,
          location_id: data.location_id,
          role_ids: data.role_ids,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/locations/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ locations: data, locationsLoaded: true })
      })
      .catch(error => this.setState({ error, locationsLoaded: true }))
  }
}

export default UpdateCustomAttribute;
