import React, { Component } from 'react';

import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class PrintButton extends Component {
  render() {
    const text = this.props.text;
    const extraClass = this.props.extraClass || "";

    return (
      <div onClick={window.print} className={`main-page print no-print ${extraClass}`}>
        <div className="edit-button print">
          <FontAwesomeIcon icon={faPrint}/>
          <h4 className="with-icon">{text || "Print"}</h4>
        </div>
      </div>
    );
  }
}

export default PrintButton;
