import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import SetTitle from '../../components/shared/SetTitle';
import HelpText from '../../components/help/HelpText';
import NoticeBox from '../../components/shared/NoticeBox';

import { adminUser, managerUser, renderErrorWarning } from '../../utilities/Forms.js'
import { futureEndDate } from '../../utilities/Generic.js'
import Audits from '../../components/shared/Audits';

class UpdateDefaultRequiredAllocation extends Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  state = {
    number_of_staff: "",
    start_date: "",
    start_time: "",
    end_time: "",
    role_id: "",
    started: false,

    min_start_time: this.generateMinStartTime(),
    max_start_time: this.generateMaxStartTime(),

    min_end_time: this.generateMinStartTime(),
    max_end_time: this.generateMaxStartTime(),

    showDeleteConfirmationModal: false,

    roles: null,
    rolesLoaded: false,
    loaded: false,

    unauthorized: "",
    errors: ""
  };

  generateMinStartTime() {
    let hour = ("0" + this.props.workStartsAt).slice(-2)
    return `${hour}:00`
  }

  generateMaxStartTime() {
    let endTime = this.props.workStartsAt + this.props.dayDuration
    let hour = ("0" + endTime).slice(-2)
    return `${hour}:00`
  }

  handleChange(event) {
    let name = event.target.name;
    let value = event.target.value;

    this.setState({[name]: value});
  }

  handleSubmit(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var json = JSON.stringify({
      "weekly_required_allocation": {
        "number_of_staff": this.state.number_of_staff,
        "start_date": this.state.start_date,
        "end_date": futureEndDate(this.state.start_date, this.state.start_time, this.state.end_time),
        "start_time": this.state.start_time,
        "end_time": this.state.end_time,
        "role_id": this.state.role_id,
        "location_id": this.props.location_id
      }
    })

    var requestOptions = {
      method: 'PUT',
      headers: headers,
      body: json,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_required_allocations/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data })
      } else {
        this.setState({ updating: false, errors: "" })
        sessionStorage.setItem("updateSuccess", "Required Allocation updated successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  handleDeleteModal() {
    this.setState({ showDeleteConfirmationModal: true });
  }

  closeModal(event) {
    this.setState({ showDeleteConfirmationModal: false })
  }

  doNotCloseModal(event) {
    event.stopPropagation();
  }

  handleDelete(event) {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'DELETE',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    var errorsInResponse = false

    this.setState({updating: true})
    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_required_allocations/${id}`, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      else if (response.status === 422) {
        errorsInResponse = true
        return response.json()
      }
      else if (response.status === 401) {
        this.setState({unauthorized: true})
      }
      else {
        throw new Error('Something went wrong ...');
      }
    })
    .then(data => {
      if (errorsInResponse) {
        this.setState({ updating: false, errors: data, showDeleteConfirmationModal: false })
      } else {
        this.setState({ updating: false, errors: "", showDeleteConfirmationModal: false })
        sessionStorage.setItem("updateSuccess", "Required Allocation deleted successfully!");
        this.props.closeEditPanel()
      }
    })
    .catch(error => this.setState({ updating: false, error, loaded: true }))

    event.preventDefault();
  }

  renderDeleteConfirmationModal() {
    if (this.state.showDeleteConfirmationModal) {
      return (
        <div className="full-modal-container" onClick={this.closeModal}>
          <div className="full-modal-content minimal" onClick={this.doNotCloseModal}>
            <div className="modal-title">Delete Weekly Required Allocation?</div>
            <label className="column">Are you sure you want to delete this Weekly Required Allocation?</label>

            <div className="center-two-buttons">
              <button className="delete modal-button red-button" onClick={this.handleDelete}>Delete</button>
              <button className="modal-button" onClick={this.closeModal}>Cancel</button>
            </div>
          </div>
        </div>
      )
    }
  }

  renderErrors(field) {
    if (this.state.errors[field]) {

      let capitalCaseField = field.charAt(0).toUpperCase() + field.slice(1)

      return (
        <div className="error">{`${capitalCaseField} ${this.state.errors[field]}`}</div>
      )
    }
  }

  renderSaveButton() {
    if (adminUser() || managerUser()) {
      return <div className="button" onClick={this.handleSubmit} disabled={this.state.updating}>Save</div>
    }
    else {
      return <div className="button" disabled="disabled">Sorry, you don't have permission to save changes</div>
    }
  }

  renderDeleteButton() {
    if (!this.state.started) {
      if (adminUser() || managerUser()) {
        return <div className="button red-button right" onClick={this.handleDeleteModal} disabled={this.state.updating}>Delete</div>
      }
    }
  }

  render() {
    const { number_of_staff, start_date, start_time, end_time, role_id, default_required_allocation_id, min_start_time, max_start_time, min_end_time, max_end_time, roles, rolesLoaded, unauthorized, errors, error } = this.state;

    if (this.state.loggedIn) {
      return <Redirect to="/"/>
    }

    if (unauthorized) {
      return <Redirect to="/login"/>
    }

    if (error) {
      return <div>{error.message}</div>;
    }

    if (rolesLoaded === false) {
      return <p>Loading ...</p>;
    }

    if (rolesLoaded) {
      return (
        <div>
          <SetTitle title={"Edit Weekly Required Allocation | Staff Fulfilment"} />
          {default_required_allocation_id &&
            <NoticeBox type="info">
              <div>This Weekly Required Allocation is attached to a template. Changing this record will only affect this week's Required Allocations and divorce this record from the template. Would you like to <Link to={`/staff-fulfilment/${this.props.location_id}/required-allocation/template/${default_required_allocation_id}`}>change the Default Required Allocation template record</Link> instead?</div>
            </NoticeBox>
          }

          <form className="settings big-settings" onSubmit={this.handleSubmit}>
            <div className="row">
              <HelpText page={'weekly_required_allocation'} section={'role'} />

              <label className="column">Role:</label>{ this.renderErrors('role') }
              <select name="role_id" onChange={this.handleChange} value={role_id}>
                <option value="">Select</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'start_date'} />

                <label className="column">Start Date:</label>{ this.renderErrors('start_date') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input type="date" name="start_date" onChange={this.handleChange} value={start_date} />
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'start_time'} />
                <label className="column">Start Time:</label>{ this.renderErrors('start_time') }
              </div>

              <div className="row">
                <HelpText page={'weekly_required_allocation'} section={'end_time'} />
                <label className="column">End Time:</label>{ this.renderErrors('end_time') }
              </div>
            </div>

            <div className="half-row">
              <div className="row">
                <input className="column" type="time" name="start_time" min={min_start_time} max={max_start_time} value={start_time} onChange={this.handleChange} />
              </div>

              <div className="row">
                <input className="column" type="time" name="end_time" min={min_end_time} max={max_end_time} value={end_time} onChange={this.handleChange} />
              </div>
            </div>

            <div className="row">
              <HelpText page={'weekly_required_allocation'} section={'number_of_staff'} />

              <label className="column">Number of Staff:</label>{ this.renderErrors('number_of_staff') }
              <input className="column" type="number" min="1" step="1" name="number_of_staff" value={number_of_staff} onChange={this.handleChange} />
            </div>

            { this.renderSaveButton() }
            { this.renderDeleteButton() }
            { renderErrorWarning(errors) }
            { this.renderDeleteConfirmationModal() }
          </form>

          <Audits klass="WeeklyRequiredAllocation" id={this.props.id} />
        </div>
      );
    }
  }

  componentDidMount() {
    let id = this.props.id;

    var headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    var requestOptions = {
      method: 'GET',
      headers: headers,
      credentials: 'include',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/weekly_required_allocations/${id}`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({
          number_of_staff: data.number_of_staff,
          start_date: data.start_date,
          end_date: data.end_date,
          start_time: data.start_time,
          end_time: data.end_time,
          role_id: data.role_id,
          default_required_allocation_id: data.default_required_allocation_id,
          started: data.started,
          loaded: true
        })
      })
      .catch(error => this.setState({ error, loaded: true }))

    fetch(`${process.env.REACT_APP_ROOT_DOMAIN}/v1/a/roles/names_and_ids`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        else if (response.status === 401) {
          this.setState({error: JSON.stringify(response.body)})
          this.setState({unauthorized: true})
        }
        else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => {
        this.setState({ roles: data, rolesLoaded: true })
      })
      .catch(error => this.setState({ error, rolesLoaded: true }))
  }
}

export default UpdateDefaultRequiredAllocation;
