import React, { Component } from 'react';

class ModalContainerTrigger extends Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  state = {
    showModal: false
  }

  openModal(event) {
    this.setState({showModal: true})

    event.preventDefault();
  }

  closeModal(event) {
    if (this.props.closeModal) {
      this.props.closeModal()
    }

    this.setState({showModal: false})

    event.preventDefault();
  }

  doNotCloseModal(event) {
    event.stopPropagation();
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({[name]: value});
  }

  renderModal() {
    const size = this.props.full ? "full-" : ""
    if (this.state.showModal || this.props.showModal) {
      return (
        <div className={`${size}modal-container`} onClick={this.closeModal}>
          <div className={`${size}modal-content`} onClick={this.doNotCloseModal}>
            <div className="modal-title">{this.props.modalTitle}</div>

              {this.props.children}

            <div className="center-two-buttons">
              <button className="small button small-top-padding" onClick={this.closeModal}>{this.props.closeName || "Close"}</button>
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <>
        {this.props.triggerText &&
          <div className={this.props.className || "small button"} onClick={this.openModal}>{this.props.triggerText}</div>
        }
        {this.renderModal()}
      </>
    );
  }
}

export default ModalContainerTrigger;
