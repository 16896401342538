import React from "react"; 
import Cookies from 'universal-cookie';

export function adminUser() {
  if (localStorage.getItem("access_levels") === null || localStorage.getItem("access_levels").split(",").includes("admin")) {
    return true
  }
  else {
    return false
  }
}

export function managerUser() {
  if (localStorage.getItem("access_levels") === null || localStorage.getItem("access_levels").split(",").includes("manager")) {
    return true
  }
  else {
    return false
  }
}

export function supportUser() {
  const cookies = new Cookies()
  if (cookies.get("support")) {
    return true
  }
  return false
}

export function hideElementForNonAdmins() {
  if (localStorage.getItem("access_levels") === null || localStorage.getItem("access_levels").split(",").includes("admin")) {
    return ""
  }
  else {
    return "hidden"
  }
}

export function hideElementForNonAdminsAndNonManagers() {
  if (localStorage.getItem("access_levels") === null || localStorage.getItem("access_levels").split(",").includes("admin") || localStorage.getItem("access_levels").split(",").includes("manager")) {
    return ""
  }
  else {
    return "hidden"
  }
}

export function displayStaffFulfilment() {
  // Check license type
  if (localStorage.license !== "base") {
    return false
  }

  // Enable on demo and development, for now.
  if (process.env.REACT_APP_ENVIRONMENT === "demo" || process.env.REACT_APP_ENVIRONMENT === "development") {
    return true
  }

  // Check add_on Location count
  if (JSON.parse(localStorage.getItem("staff_fulfilment_access")) === true) {
    return true
  }

  return false
}

export function capitalize(value) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function capitalizeAllWords(value) {
  return value.split(' ').map(capitalize).join(' ')
}

export function spaceAndCapitalize(string) {
  if (string === null) {
    return "Null"
  }

  if (typeof string === "number") {
    return string
  }

  if (Array.isArray(string)) {
    return `[${string.join(", ")}]`
  }

  if (typeof string === "object") {
    return JSON.stringify(string)
  }

  if (typeof string === "boolean") {
    string = string.toString()
  }

  return string.split("_").map((word) => capitalize(word)).join(" ")
}

export function combine(array) {
  let output = []

  array.forEach((value) => {
    output.push(capitalize(value))
  })

  output = output.join(", ")
  return output
}

export function renderErrorWarning(errors) {
  if (errors) {
    return (
      <div className="error">
        Your form has some errors. Please scroll up to view the messages.
      </div>
    )
  }
}

export function renderErrors(errors, field, optionalWord) {
  const errorText = errors[field]

  if (errorText) {
    // Remove `ticket_action` from `ticket_action.body` to leave us with just `body`
    if (field.includes('.')) {
      field = field.split('.')[1]
    }

    // Override title with optional word
    if (optionalWord) {
      field = optionalWord
    }

    let words = field.split('_');
    let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let formattedField = capitalizedWords.join(' ');

    return (
      <div className="error">{`${formattedField} ${errorText.join(" and ")}`}</div>
    )
  }
}
